import React, { useCallback, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useIntl, FormattedMessage } from 'react-intl'

import { Header, Input, Button, SelectInput } from 'components/common/atoms'
import { Stack } from 'components/layout'
import { IUserCard, IUserInvoice, IUserOrganization } from 'interface'
import { TPSAPI } from 'api/users'
import { AddVoucher, Dialog } from 'components/common/molecules'
import ClassicCard from 'components/common/atoms/ClassicCard'
import { functionalUpdate } from 'react-table'

const UserCredits: React.FC = () => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    getCredit()
  }, [])
  const [userCredit, setUserCredit] = React.useState<string>('0')
  const getCredit = async () => {
    const response = await TPSAPI.getUserCredit()
    setUserCredit(response.creditValue)
  }

  const onComplete = async () => {
    getCredit();
  }

  const primaryButton = {
    buttonText: 'Convert',
    action: () => {
      setDialogOpen(false);
    },
  };

  const secondaryButton = {
    buttonText: 'Cancel',
    action: () => {
      setDialogOpen(false);
    },
  };

  return (
    <>
      <ClassicCard otherClasses="flex w-full p-4 h-full">
        <div className="w-full flex flex-col md:flex-row justify-between items-center space-y-6 md:space-y-0">
          <div className='flex flex-col items-center md:items-start md:mr-4'>
            <h3 className="text-md font-bold  dark:text-white">Blindspot Credits</h3>
            <p className="text-sm font-semibold text-dark-500 dark:text-dark-300 mt-2 text-center md:text-left">Blindspot credits are basically cash! You can use them to fund your future campaigns (or just brag about how many you have).</p>
          </div>
          <div className='flex flex-col md:flex-row justify-end space-y-6 md:space-y-0'>
            <div className="text-center flex flex-row items-center justify-center md:border-r border-dark-300 border-opacity-30 md:pr-8 md:mr-8">
              <div className='w-5 h-5 border border-[#2ECC71] rounded-2xl flex items-center justify-center mr-4'>
                <div className='w-2 h-2 bg-[#2ECC71] rounded-2xl'></div>
              </div>
              <h3 className="text-2xl font-bold  dark:text-white mr-2 whitespace-nowrap">{userCredit}</h3>
              <p className="text-sm font-semibold text-dark-500 text-center whitespace-nowrap dark:text-dark-400">active credits</p>
            </div>
            <div className='min-w-[230px]'>
              <AddVoucher isPercentage={false} labelName="Voucher code" onComplete={() => { onComplete() }} />
            </div>
          </div>
        </div>
      </ClassicCard>
    </>
  )
}
export default UserCredits
