import React, { useState, useEffect, useContext } from 'react';

import Avatar from 'avataaars';
import styled from 'styled-components';
// import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import { Accordion } from '@material-ui/core';
import { Button } from '../atoms';
import Fab from '@material-ui/core/Fab';
import { JWT, TPSAPI } from 'api';

import { Send, Edit, ChevronRightCircle, ChevronLeftCircle } from 'react-iconly';
import { useApp } from 'components/app';
const pieces: any = {
  skinColor: ['Yellow', 'Pale', 'Light', 'Tanned', 'Brown', 'DarkBrown', 'Black'],
  topType: [
    'NoHair',
    'Eyepatch',
    'Hat',
    'Hijab',
    'Turban',
    'WinterHat1',
    'WinterHat2',
    'WinterHat3',
    'WinterHat4',
    'LongHairBigHair',
    'LongHairBob',
    'LongHairBun',
    'LongHairCurly',
    'LongHairCurvy',
    'LongHairDreads',
    'LongHairFrida',
    'LongHairFro',
    'LongHairFroBand',
    'LongHairNotTooLong',
    'LongHairShavedSides',
    'LongHairMiaWallace',
    'LongHairStraight',
    'LongHairStraight2',
    'LongHairStraightStrand',
    'ShortHairDreads01',
    'ShortHairDreads02',
    'ShortHairFrizzle',
    'ShortHairShaggyMullet',
    'ShortHairShortCurly',
    'ShortHairShortFlat',
    'ShortHairShortRound',
    'ShortHairShortWaved',
    'ShortHairSides',
    'ShortHairTheCaesar',
    'ShortHairTheCaesarSidePart',
  ],
  mouthType: [
    'Concerned',
    'Default',
    'Disbelief',
    'Eating',
    'Grimace',
    'Sad',
    'ScreamOpen',
    'Serious',
    'Smile',
    'Tongue',
    'Twinkle',
    'Vomit',
  ],
  eyebrowType: [
    'Angry',
    'AngryNatural',
    'Default',
    'DefaultNatural',
    'FlatNatural',
    'RaisedExcited',
    'RaisedExcitedNatural',
    'SadConcerned',
    'SadConcernedNatural',
    'UnibrowNatural',
    'UpDown',
    'UpDownNatural',
  ],
  accessoriesType: ['Blank', 'Kurt', 'Prescription01', 'Prescription02', 'Round', 'Sunglasses', 'Wayfarers'],
  hairColor: [
    'Auburn',
    'Black',
    'Blonde',
    'BlondeGolden',
    'Brown',
    'BrownDark',
    'PastelPink',
    'Platinum',
    'Red',
    'SilverGray',
  ],
  facialHairType: ['BeardLight', 'BeardMajestic', 'BeardMedium', 'Blank', 'MoustacheFancy', 'MoustacheMagnum'],
  clotheType: [
    'BlazerShirt',
    'BlazerSweater',
    'CollarSweater',
    'GraphicShirt',
    'Hoodie',
    'Overall',
    'ShirtCrewNeck',
    'ShirtScoopNeck',
    'ShirtVNeck',
  ],
  clotheColor: [
    'Black',
    'Blue01',
    'Blue02',
    'Blue03',
    'Gray01',
    'Gray02',
    'Heather',
    'PastelBlue',
    'PastelGreen',
    'PastelOrange',
    'PastelRed',
    'PastelYellow',
    'Pink',
    'Red',
    'White',
  ],
  eyeType: [
    'Close',
    'Cry',
    'Default',
    'Dizzy',
    'EyeRoll',
    'Happy',
    'Hearts',
    'Side',
    'Squint',
    'Surprised',
    'Wink',
    'WinkWacky',
  ],
};

const OptionsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  grid-gap: 10px;
  width: 100%;
`;
const BrowseStyleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .BS-Label {
    font-weight: 600;
    margin: 0;
    font-size: 15px;
  }

  .BS-Prev,
  .BS-Next {
    background: white;
    height: 25px;
    border-radius: 6px 0 0 6px;
    cursor: pointer;
    transition: all 0.1s linear;

    &:active {
      color: white;
    }
  }

  .BS-Next {
    border-radius: 0 6px 6px 0;
  }

  .BS-Content {
    display: flex;
    background: white;
    border-radius: 10px;
  }

  .BS-Counter {
    width: 50px;
    font-weight: 900;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }
`;

const BrowseStyleContainerDark = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .BS-Label {
    font-weight: 600;
    color: #E4DEDE;
    margin: 0;
    font-size: 15px;
  }

  .BS-Prev,
  .BS-Next {
    background: #1A1A1A;
    height: 25px;
    border-radius: 6px 0 0 6px;
    cursor: pointer;
    transition: all 0.1s linear;
    color: #F64747;

    &:active {
      color: white;
    }
  }

  .BS-Next {
    border-radius: 0 6px 6px 0;
  }

  .BS-Content {
    display: flex;
    background: #1A1A1A;
    border-radius: 10px;
  }

  .BS-Counter {
    width: 50px;
    font-weight: 900;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #E4DEDE;
  }
`;

const BrowseStyle = (props: any) => {
  const [current, setCurrent] = useState(0);
  const [themeMode, setThemeMode] = React.useState<string | undefined>(undefined);
  const [total, setTotal] = useState(0);
  const { name, onChange, label } = props;
  const pieceArray = pieces[name];

  useEffect(() => {
    const items = localStorage.getItem('mode');
    if (items) {
      setThemeMode(items);
    }
    setTotal(pieceArray.length);
    let newIndex;
    if (props.starter) {
      newIndex = pieceArray.indexOf(props.starter);
    } else {
      newIndex = Math.floor(Math.random() * pieceArray.length);
    }
    setCurrent(newIndex);
    onChange(name, pieceArray[newIndex]);
  }, []);

  const onPrev = () => {
    let index = current;
    if (--index < 0) {
      index = total - 1;
    }
    setCurrent(index % total);
    onChange(name, pieceArray[index % total]);
  };

  const onNext = () => {
    let index = current;
    if (++index > total) {
      index = 0;
    }
    setCurrent(index % total);
    onChange(name, pieceArray[index % total]);
  };

  return (
      (themeMode !== 'darkMode' ?
        (
          <BrowseStyleContainer>
            <p className="BS-Label">{label}</p>
            <div className="BS-Content">
              <div className="BS-Prev" onClick={() => onPrev()}>
                <ChevronLeftCircle set="bold" />
              </div>
              <div className="BS-Counter">
                {current + 1} / {total}
              </div>
              <div className="BS-Next" onClick={() => onNext()}>
                <ChevronRightCircle set="bold" />
              </div>
            </div>
          </BrowseStyleContainer>
        ) :
        (
          <BrowseStyleContainerDark>
            <p className="BS-Label">{label}</p>
            <div className="BS-Content">
              <div className="BS-Prev" onClick={() => onPrev()}>
                <ChevronLeftCircle set="bold" />
              </div>
              <div className="BS-Counter">
                {current + 1} / {total}
              </div>
              <div className="BS-Next" onClick={() => onNext()}>
                <ChevronRightCircle set="bold" />
              </div>
            </div>
          </BrowseStyleContainerDark>
        )
      )
    )
};

const getRandomEntry = (arr: any) => {
  return arr[Math.floor(Math.random() * arr.length)];
};

const getRandomAvatar = () => {
  return JSON.stringify({
    avatarStyle: 'Circle',
    topType: getRandomEntry(pieces.topType),
    accessoriesType: getRandomEntry(pieces.accessoriesType),
    hairColor: getRandomEntry(pieces.hairColor),
    facialHairType: getRandomEntry(pieces.facialHairType),
    clotheType: getRandomEntry(pieces.clotheType),
    clotheColor: getRandomEntry(pieces.clotheColor),
    eyeType: getRandomEntry(pieces.eyeType),
    eyebrowType: getRandomEntry(pieces.eyebrowType),
    mouthType: getRandomEntry(pieces.mouthType),
    skinColor: getRandomEntry(pieces.skinColor),
  });
};

const AvatarEditor = (props: any) => {
  const randomStarter = getRandomAvatar();
  const { width = '150px', height = '150px', edit } = props;
  const starter = props.starter || randomStarter;
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const starterObj = JSON.parse(starter);
  const { notify, setReloadJwt, reloadJwt } = useApp();

  starterObj.avatarStyle = 'Circle';
  // const [styles, setStyles] = useState({
  //   avatarStyle: 'Circle',
  //   topType: 'LongHairMiaWallace',
  //   accessoriesType: 'Prescription01',
  //   hairColor: 'BrownDark',
  //   facialHairType: 'Blank',
  //   clotheType: 'Hoodie',
  //   clotheColor: 'PastelBlue',
  //   eyeType: 'Happy',
  //   eyebrowType: 'Default',
  //   mouthType: 'Smile',
  //   skinColor: 'Light',
  // });

  const [styles, setStyles] = useState(starterObj);

  const { classes } = props;

  const onChange = (part: any, value: any) => {
    const newStyles = Object.assign({}, styles);
    newStyles[part] = value;
    setStyles(newStyles);
  };

  // setTimeout(() => {
  //   const newStyles = Object.assign({}, styles);
  //   newStyles['clotheColor'] = 'PastelRed';
  //   setStyles(newStyles);
  // }, 3000);

  const saveAvatar = async () => {
    try {
      const response = await TPSAPI.saveAvatar(JSON.stringify(styles));
      JWT.setJwt(response.data.data.token);
      notify('Avatar saved succesfuly', 'success');
      setReloadJwt(reloadJwt + 1);
    } catch (error: any) {
      notify(error?.message || 'Please try again later', 'error');
    }

    // setLoading(true)
    // try {
    //   const result = await REST.post('/users/update-avatar', {
    //     avatar: JSON.stringify(styles)
    //   })
    //   User.updateUserToken(result)
    //   setLoading(false)
    //   App.snack('Avatar saved succesfuly', 'success')
    // } catch (err) {
    //   setLoading(false)
    //   App.snack(err, 'error')
    // }
  };

  return (
    <div className="relative flex flex-col items-center justify-center">
      <div className="relative">
        <Avatar
          key="basic_profile"
          style={{ width: width, height: height }}
          avatarStyle={styles.avatarStyle}
          topType={styles.topType}
          accessoriesType={styles.accessoriesType}
          hairColor={styles.hairColor}
          facialHairType={styles.facialHairType}
          clotheType={styles.clotheType}
          clotheColor={styles.clotheColor}
          eyeType={styles.eyeType}
          eyebrowType={styles.eyebrowType}
          mouthType={styles.mouthType}
          skinColor={styles.skinColor}
        />
        <div className="absolute bottom-0 right-0">
          {edit && (
            <Fab
              color={expanded ? 'primary' : 'secondary'}
              aria-label="Edit"
              className="w-8 h-8 md:w-11 md:h-11"
              onClick={() => {
                if (expanded) {
                  setExpanded(false);
                } else {
                  setExpanded(true);
                }
              }}
            >
              {expanded ? <Send set="light" /> : <Edit set="light" />}
            </Fab>
          )}
        </div>
      </div>
      {edit && (
        <div className="w-full mx-auto avatar-customize">
          <Accordion
            //   classes={{ root: classes.expansion }}
            style={{ background: 'transparent', border: 0, boxShadow: 'none' }}
            expanded={expanded}
            onChange={(ev, exp) => {
              setExpanded(exp);
            }}
          >
            <div
              style={{ display: 'none' }}
              // expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              {/* <Typography className={classes.heading}>Edit</Typography> */}
            </div>
            <div className="py-5">
              <OptionsContainer>
                <BrowseStyle name="skinColor" label="Skin" onChange={onChange} starter={starterObj['skinColor']} />
                <BrowseStyle name="topType" label="Hair style" onChange={onChange} starter={starterObj['topType']} />
                <BrowseStyle
                  name="accessoriesType"
                  label="Accessories"
                  onChange={onChange}
                  starter={starterObj['accessoriesType']}
                />
                <BrowseStyle
                  name="hairColor"
                  label="Hair color"
                  onChange={onChange}
                  starter={starterObj['hairColor']}
                />
                <BrowseStyle name="eyeType" label="Eyes" onChange={onChange} starter={starterObj['eyeType']} />
                <BrowseStyle
                  name="eyebrowType"
                  label="Eyebrows"
                  onChange={onChange}
                  starter={starterObj['eyebrowType']}
                />
                <BrowseStyle name="mouthType" label="Mouth" onChange={onChange} starter={starterObj['mouthType']} />
                <BrowseStyle
                  name="facialHairType"
                  label="Facial hair"
                  onChange={onChange}
                  starter={starterObj['facialHairType']}
                />
                <BrowseStyle name="clotheType" label="Clothes" onChange={onChange} starter={starterObj['clotheType']} />
                <BrowseStyle
                  name="clotheColor"
                  label="Clothes color"
                  onChange={onChange}
                  starter={starterObj['clotheColor']}
                />
              </OptionsContainer>
            </div>
            {/* <Divider /> */}
            <div className="flex items-center justify-center w-full pb-5 mx-auto">
              <Button loading={loading} onClick={() => saveAvatar()}>
                Update avatar
              </Button>
            </div>
          </Accordion>
        </div>
      )}
    </div>
  );
};

export default AvatarEditor;
