import React from 'react';
import { useSession, signIn, signOut } from "next-auth/react"

type SyncGoogleAccountProps = {

};

const SyncGoogleAccount = ({ }: SyncGoogleAccountProps) => {
  const { data: session } = useSession()
  if (session) {
    console.log('sesson', session.user)
  }

  return (
    <>
      <div className='relative mt-6 w-full flex items-center justify-center sync-divider'>
       <div className='h-full w-fit z-10 dark:bg-dark-200 bg-white px-4 dark:text-dark-300'>or sync with</div>
      </div>

      <div 
        onClick={() => signIn("google")}
        data-cy="createAccount"
        className="w-full hover:shadow-secondaryFullHover focus:shadow-secondaryFullHover border border-dark-default dark:border-white relative rounded-3xl p-3 mx-auto mt-7 text-dark dark:text-white font-semibold text-center cursor-pointer"
      >
        <div className='absolute top-3 left-6 border-r border-dark-default dark:border-white dark:border-opacity-50 pr-4'>
          <img className='h-6' src="/images/googleaccount.svg"></img>
        </div>
        Google Account
      </div>
    </>
  );
};

SyncGoogleAccount.defaultProps = {};

export default SyncGoogleAccount;
