import React, { useEffect } from 'react';
import ReactMapboxGl, { Marker, Cluster, Popup, ZoomControl, RotationControl } from 'react-mapbox-gl';
import { Icon } from '@iconify/react-with-api';

import ClusterMarker from '../atoms/ClusterMarker';
import { ISugestion } from 'interface/ISugestion';
import { ILocation } from 'interface/ILocation';
const MAPBOX_TOKEN = 'pk.eyJ1IjoidHBzZW5nYWdlIiwiYSI6ImNqZGluMGcyazEybGcycW4wdnhwanR3cXcifQ.XpvUi6PLguDLfYrksmcDxw';
const Mapbox = ReactMapboxGl({ accessToken: MAPBOX_TOKEN });
import { PublishContext } from 'context/publish/publish.provider';
import { useMapContext } from 'context/publish/map.provider';

import _ from 'lodash';
import { IPublishType } from 'context/publish/publish.reducer';
import { geoToH3, kRing, h3ToGeo, h3GetResolution } from 'h3-js';
import Campaigns from 'api/campaigns/campaign';
import geojson2h3 from 'geojson2h3';
import { Button, SelectInput } from '../atoms';
import MapSearch from '../molecules/MapSearch';
import MapPin from '../molecules/MapPin';
const styles = {
  map: {
    width: '100%',
    marginLeft: '0',
    height: '100%',
  },
  marker: {
    width: 60,
    height: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const PublishMapMap = () => {
  let mapRef = React.useRef<any>(null);

  const { publishState, dispatch } = React.useContext(PublishContext);
  const [mapLocation, setMapLocations] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<any>(true);
  const { searchText, locationType, setSearchText, setLocationType } = useMapContext();
  const [resolution, setResolution] = React.useState<string>('0');
  const [showHex, setShowHex] = React.useState<boolean>(false);
  const [hasLayers, setHasLayers] = React.useState<boolean>(false);
  const [themeMode, setThemeMode] = React.useState<string | null>(null);

  const onChange = (location: ISugestion) => {
    if (mapRef.current && location && location.latitude && location.longitude) {
      // getLocation(parseFloat(location.latitude), parseFloat(location.longitude))
      dispatch({
        type: IPublishType.setMapCenter,
        payload: [parseFloat(location.longitude), parseFloat(location.latitude)],
      });
      dispatch({
        type: IPublishType.setMapZoom,
        payload: [14],
      });

      mapRef.current.flyTo({
        center: [parseFloat(location.longitude), parseFloat(location.latitude)],
        offset: [0, -200],
        zoom: 14,
      });
    }
  };

  const distance = (lat1: any, lon1: any, lat2: any, lon2: any, unit: any) => {
    if (lat1 === lat2 && lon1 === lon2) {
      return 0;
    } else {
      let radlat1 = (Math.PI * lat1) / 180;
      let radlat2 = (Math.PI * lat2) / 180;
      let theta = lon1 - lon2;
      let radtheta = (Math.PI * theta) / 180;
      let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = (dist * 180) / Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit === 'K') {
        dist = dist * 1.609344;
      }
      if (unit === 'N') {
        dist = dist * 0.8684;
      }
      return dist;
    }
  };
  const getResolution = (zoom: number): number => {
    switch (Math.floor(zoom)) {
      case 0:
        return 0;
      case 1:
        return 0;
      case 2:
        return 0;
      case 3:
        return 2;
      case 4:
        return 3;
      case 5:
        return 3;
      case 6:
        return 3;
      case 7:
        return 4;
      case 8:
        return 4;
      case 9:
        return 5;
      case 10:
        return 6;
      case 11:
        return 6;
      case 12:
        return 7;
      case 13:
        return 7;
      case 14:
        return 7;
      case 15:
        return 10;
      case 16:
        return 11;
      case 17:
        return 13;
      case 18:
        return 13;
      case 19:
        return 13;
      case 20:
        return 13;
      case 21:
        return 14;
      case 22:
        return 15;
    }
    return 0;
  };
  const getSize = (size: number) => {
    if (size < 10) {
      return 'w-10  h-10 bg-green-600';
    }

    if (size > 10 && size < 100) {
      return 'w-16 h-16 bg-green-600';
    }
    if (size > 100 && size < 500) {
      return 'w-20 h-16 bg-green-600';
    }
    if (size > 500) {
      return 'w-24 h-16 bg-green-600';
    }
  };
  useEffect(() => {
    const items = localStorage.getItem('mode');
    if (items) {
      setThemeMode(items);
    }
    if (mapRef.current) {
      //   checkMap(mapRef.current)
    }
  }, [searchText]);

  const checkMap = _.debounce(async map => {
    setLoading(true);
    const h3Index = geoToH3(map.getCenter().lat, map.getCenter().lng, parseInt(resolution, 10));
    let i = kRing(h3Index, 10);
    const bounds = map.getBounds();

    // const data = await Campaigns.getLocationsCluster(
    //   i,
    //   parseInt(resolution, 10),
    //   bounds.getWest(),
    //   bounds.getSouth(),
    //   bounds.getEast(),
    //   bounds.getNorth(),
    //   map.getZoom(),
    //   {
    //     searchText: searchText
    //   }
    // )
    // setMapLocations(data.locations)
    // setLoading(false)

    // dispatch({
    //   type: IPublishType.setMapCenter,
    //   payload: [map.getCenter().lng, map.getCenter().lat]
    // })
    // dispatch({
    //   type: IPublishType.setMapZoom,
    //   payload: [map.getZoom()]
    // })
  }, 500);

  const getFeatures = (bbox: any) => {
    const bboxFeature: any = {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [bbox._sw.lng, bbox._sw.lat],
            [bbox._sw.lng, bbox._ne.lat],
            [bbox._ne.lng, bbox._ne.lat],
            [bbox._ne.lng, bbox._sw.lat],
            [bbox._sw.lng, bbox._sw.lat],
          ],
        ],
      },
    };

    const hexagons = geojson2h3.featureToH3Set(bboxFeature, parseInt(resolution, 10));

    const hexPolygon = geojson2h3.h3SetToMultiPolygonFeature(hexagons);

    const points = {
      type: 'FeatureCollection',
      features: hexagons.map((hex: any) => ({
        type: 'Feature',
        properties: {
          hex: hex,
        },
        geometry: {
          type: 'Point',
          coordinates: h3ToGeo(hex).reverse(),
        },
      })),
    };

    return [hexPolygon, points];
  };
  useEffect(() => {
    generateHex();
    setHasLayers(true);
  }, [showHex, resolution]);
  const updateLayer = (PlmhasLayers: any) => {
    if (mapRef.current) {
      const map = mapRef.current;
      try {
        const [hexFeatures, pointFeatures] = getFeatures(map.getBounds());
        map.getSource('geojson').setData(hexFeatures);
        map.getSource('label').setData(pointFeatures);
      } catch (error) {
        console.log(error);
      }
    }
  };
  const generateHex = () => {
    if (mapRef.current) {
      const map = mapRef.current;
      try {
        if (hasLayers) {
          map.removeLayer('points');
          map.removeLayer('geojsonLayer');
          map.removeSource('label');
          map.removeSource('geojson');
        }
      } catch (error) {}
      if (showHex) {
        setMapLocations([]);
        const [hexFeatures, pointFeatures] = getFeatures(map.getBounds());
        map.addSource('geojson', {
          type: 'geojson',
          data: hexFeatures,
        });
        map.addSource('label', {
          type: 'geojson',
          data: pointFeatures,
        });

        // Add hexagon layer (outline of h3 hexagon)
        map.addLayer({
          id: 'geojsonLayer',
          type: 'line',
          source: 'geojson',
          layout: {},
          paint: {
            'line-width': 1,
            'line-color': '#00a0ff',
            'line-opacity': 0.8,
          },
        });

        // Add text label layer (text of h3 index)
        map.addLayer({
          id: 'points',
          type: 'symbol',
          source: 'label',
          glyphs: 'http://fonts.openmaptiles.org/{fontstack}/{range}.pbf',
          layout: {
            'text-field': '{hex}',
            'text-size': 12,
            'text-anchor': 'center',
          },
          paint: {
            'text-color': '#00a0ff',
          },
        });
        setHasLayers(true);
      } else {
      }
    }
  };
  return (
    <div className="pl-24" style={{ width: '100%', height: '900px' }}>
      {loading && (
        <div className="absolute bottom-0 right-0 z-50 flex items-center justify-center w-32 h-20 bg-opacity-25 ">
          <img src="/images/loading.svg" className={` pointer-events-none  w-32 `} />
        </div>
      )}
      <Mapbox
        style={themeMode === 'darkMode' ? 'mapbox://styles/tpsengage/cl4xtn4nr000914nvbn65ipuv' : 'mapbox://styles/tpsengage/ckxynelz3es3d15qzp2vx7a33'}
        movingMethod="easeTo"
        center={publishState.center}
        zoom={publishState.zoom}
        containerStyle={styles.map}
        onMoveEnd={() => {
          updateLayer(hasLayers);
        }}
        onZoomEnd={() => {
          updateLayer(hasLayers);
        }}
        onDragEnd={() => {
          updateLayer(hasLayers);
        }}
        onStyleLoad={map => {
          mapRef.current = map;
        }}
      >
        {/* <Cluster ClusterMarkerFactory={ClusterMarker} zoomOnClick>
          {mapLocation &&
            mapLocation.map((location: any, key: number) => (
              <Marker
                key={key}
                style={styles.marker}
                onClick={() => markerClick(location)}
                coordinates={[parseFloat(location.properties.long_x), parseFloat(location.properties.lat_y)]}
              >
                <MapPin
                  type={location.tp}
                  hasDeal={location.locations.hd}
                  isSelected={Boolean(
                    publishState.locations?.find((el) => {
                      return el.location.uuid === location.uuid
                    })
                  )}
                />
              </Marker>
            ))}
        </Cluster> */}

        {mapLocation &&
          mapLocation.map((location: any, key: any) => (
            <Marker
              key={key}
              style={styles.marker}
              onClick={() => {}}
              coordinates={[location.position.lat, location.position.lng]}
            >
              {location.type === 'pin' ? (
                <MapPin type={location.tp} isSelected={false} />
              ) : (
                <div className={` rounded-full flex items-center  shadow-md ${getSize(location.total)}`}>
                  <div className="w-full text-base text-center text-white">
                    <span>{location.total}</span>
                  </div>
                </div>
              )}
            </Marker>
          ))}

        <div style={{ top: '60px', position: 'relative' }}>
          <ZoomControl />
          <RotationControl />
        </div>
      </Mapbox>
      <div className="fixed bottom-0 right-0 z-50 w-64 p-5 bg-white">
        <SelectInput
          onChange={(e: any) => {
            setResolution(e.value);
          }}
          options={[
            {
              label: '0',
              value: '0',
            },
            {
              label: '1',
              value: '1',
            },
            {
              label: '2',
              value: '2',
            },
            {
              label: '3',
              value: '3',
            },
            {
              label: '4',
              value: '4',
            },
            {
              label: '5',
              value: '5',
            },
            {
              label: '6',
              value: '6',
            },
            {
              label: '7',
              value: '7',
            },
            {
              label: '8',
              value: '8',
            },
            {
              label: '9',
              value: '9',
            },
            {
              label: '10',
              value: '10',
            },
            {
              label: '11',
              value: '11',
            },
            {
              label: '12',
              value: '12',
            },
            {
              label: '13',
              value: '13',
            },
            {
              label: '14',
              value: '14',
            },
            {
              label: '15',
              value: '15',
            },
          ]}
          defaultValue={{
            label: resolution,
            value: resolution,
          }}
          isMulti={false}
          isClearable={false}
          selName="input 1"
        />

        {!showHex ? (
          <>
            <Icon
              onClick={() => {
                setShowHex(true);
              }}
              icon="entypo:eye"
              width="2rem"
              height="2rem"
              className="text-black"
            />{' '}
            <label>Show grid</label>
          </>
        ) : (
          <>
            <Icon
              onClick={() => {
                setShowHex(false);
              }}
              icon="entypo:eye-with-line"
              width="2rem"
              height="2rem"
              className="text-black"
            />
            <label>Hide grid</label>
          </>
        )}
        <div>Zoom level: {publishState.zoom}</div>
        <div>haslayers: {`${hasLayers}`}</div>
        <div>
          <Button
            fill="outline"
            onClick={() => {
              if (mapRef.current) checkMap(mapRef.current);
            }}
          >
            Load locations
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PublishMapMap;
