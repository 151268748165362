import { useApp } from 'components/app';
import { PublishContext } from 'context/publish/publish.provider';
import { IPublishType } from 'context/publish/publish.reducer';
import { IRulesApi } from 'interface';
import { ILocationContentType } from 'interface/ILocation';
import { IMediaUpload } from 'interface/IMedia';
import React, { useEffect, useRef, useState } from 'react';
import { LightBox, Dropzone, StatusTag, RadioBox, Radio, Button } from '../atoms';
import ClassicCard from '../atoms/ClassicCard';
import ReactTooltip from 'react-tooltip';
import { Dialog, MediaCard } from './index';
import PreviewMediaCard from './PreviewMediaCard';
import { MediaDialogLibraryLibrary } from './MediaDialogLibrary';
import useOnboardingContext from 'context/OnboardingContext';

const baseUrl = 'https://storage.googleapis.com/engage-uploaded-videos/';

type IProps = {
  resolution: [number, number];
  uploadedmedia: IMediaUpload[];
  rules: IRulesApi[];
  uuid: string;
  duration: number;
  durations: number[];
  activeMedia: string;
  locationsName: string[];
  contentType: ILocationContentType;
  index: number;
};
const MediaFormatDropzone: React.FC<IProps> = ({
  resolution,
  uploadedmedia,
  rules,
  uuid,
  contentType,
  activeMedia,
  durations,
  locationsName,
  duration,
  index,
}: IProps) => {
  const { notify } = useApp();
  const [uploadedFiles, setUploadedFiles] = React.useState<string[]>([]);
  const { publishState, dispatch } = React.useContext(PublishContext);
  const [hasNoDefault, setHasNoDefault] = React.useState<boolean>(false);
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [showLocationsList, setShowLocationsList] = React.useState<boolean>(true);
  const [confirmFormatDialogOpen, setConfirmFormatDialogOpen] = React.useState<boolean>(false);
  const [imagePreview, setImagePreview] = React.useState<string>('');
  const [durationOptions, setDurationOptions] = React.useState<any>([]);
  const [selectedDuration, setSelectedDuration] = React.useState<number>(duration);
  const [intendedDurationValue, setIntendedDurationValue] = React.useState<number>(selectedDuration);
  const [cancelTrigger, setCancelTrigger] = React.useState<number>(0);

  const [selectedMediaPreview, setSelectedMediaPreview] = React.useState<any>();
  const [formatMedia, setFormatMedia] = React.useState<IMediaUpload[]>([]);
  const [showMediaLibrary, setShowMediaLibrary] = useState<boolean>(false);

  const dropRef = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    checkForRemovalBasedOnDuration();
    return () => {};
  }, [publishState.toggleMedia, selectedDuration]);

  const isDurationInvalid = (media: any, formatDuration: number) => {
    let isImage = false;
    if ((media.file.type && media.file.type.includes('image')) || (media.oldType && media.oldType.includes('image'))) {
      isImage = true;
    }
    if (isImage) {
      return false;
    }
    return formatDuration && (media.duration - formatDuration > 0.5 || formatDuration - media.duration > 0.5);
  };

  const checkForRemovalBasedOnDuration = () => {
    uploadedmedia.map((singleMedia: any) => {
      if (isDurationInvalid(singleMedia, selectedDuration)) {
        rejectMedia(singleMedia.uid);
        notify('File removed based on selected media duration.', 'error');
      }
      return singleMedia;
    });
  };

  const onMediaDrop = (acceptedFinal: any, rejetedFiels: string[]) => {
    if (rejetedFiels.length > 0) {
      notify('Some files were rejected. Please only use images', 'error');
    } else {
      acceptedFinal.forEach((media: any) => {
        media.type = 'uploaded';
        media.rejected = null;
      });
      dispatch({
        type: IPublishType.setMedia,
        payload: {
          type: 'remoteUpload',
          formatId: uuid,
          media: acceptedFinal,
        },
      });

      let f = [...formatMedia].concat(acceptedFinal);
      setFormatMedia(f);
    }
  };

  const onPreviewImage = (media: any) => {
    console.log('media', media);
    if (media.action === 'isuploaded' || media.type.includes('draft')) {
      setImagePreview(media.src);
      setOpenDialog(true);
      const newMedia = { ...media };
      if (newMedia.oldUid) {
        const ext = media.oldName.split('.');
        const n = `${media.oldUid}.${ext[ext.length - 1]}`;
        newMedia.oldFilename = n;
      }
      setSelectedMediaPreview(newMedia);
    }
  };

  const rejectMediaFaild = (fileUuid: string) => {
    let element = [...formatMedia].find(el => {
      return el.uid === fileUuid;
    });

    if (element) {
      const indexN = [...formatMedia].indexOf(element);
      if (indexN > -1) {
        formatMedia.splice(indexN, 1);
        setFormatMedia([...formatMedia]);
      }
    }
  };

  const updateVideoDuration = (mediaUid: string, durationN: number) => {
    dispatch({
      type: IPublishType.updateVideoCardDuration,
      payload: {
        formatId: uuid,
        mediaId: mediaUid,
        durationN,
      },
    });
  };

  const rejectMedia = (fileUuid: string) => {
    let l = [...formatMedia].filter(el => {
      return el.uid !== fileUuid;
    });

    dispatch({
      type: IPublishType.removeMediaToFormat,
      payload: {
        fileUuid,
        formatUuid: uuid,
      },
    });
    setFormatMedia(l);
  };

  useEffect(() => {
    let hasContextual = 0;
    for (const item of formatMedia) {
      if (item.rules.length > 0) {
        hasContextual++;
      }
    }
    if (formatMedia.length !== 0) {
      if (hasContextual === formatMedia.length) {
        setHasNoDefault(true);
      } else {
        setHasNoDefault(false);
      }
    } else {
      setHasNoDefault(false);
    }
    return () => {};
  }, [publishState.toggleMedia]);

  useEffect(() => {
    calculateDurations(duration);
    setFormatMedia(uploadedmedia);
    return () => {};
  }, []);

  useEffect(() => {
    calculateDurations(duration);
    return () => {};
  }, [publishState.formats]);

  useEffect(() => {
    setCancelTrigger(cancelTrigger + 1);
    return () => {};
  }, [durationOptions]);

  const calculateDurations = (value: number) => {
    const durationsOptionsLoc = durations.map(el => {
      return {
        label: `${el}`,
        value: `${el}`,
        checked: `${el}` === `${value}`,
      };
    });
    setDurationOptions(durationsOptionsLoc);
  };

  const primaryButton = {
    buttonText: 'Confirm',
    action: () => {
      onMediaDurationConfirm();
    },
  };

  const secondaryButton = {
    buttonText: 'Cancel',
    action: () => {
      onMediaDurationCancel();
    },
  };

  const onMediaDurationConfirm = (value?: number) => {
    dispatch({
      type: IPublishType.setMediaDuration,
      payload: {
        value: value ? value : intendedDurationValue,
        index: index,
      },
    });
    setSelectedDuration(value ? value : intendedDurationValue);
    calculateDurations(value ? value : intendedDurationValue);
    setConfirmFormatDialogOpen(false);
  };
  const onMediaDurationCancel = () => {
    dispatch({
      type: IPublishType.setMediaDuration,
      payload: {
        value: selectedDuration,
        index: index,
      },
    });

    calculateDurations(selectedDuration);
    setConfirmFormatDialogOpen(false);
  };

  const setRadio = (value: number) => {
    if (formatMedia.length > 0) {
      setConfirmFormatDialogOpen(true);
      setIntendedDurationValue(value);
    } else {
      setIntendedDurationValue(value);
      onMediaDurationConfirm(value);
    }
  };

  return (
    <>
      {openDialog && (
        <LightBox
          closeDialog={() => {
            setOpenDialog(false);
          }}
          photos={[imagePreview]}
          mediaDetails={selectedMediaPreview}
          locationName={selectedMediaPreview.oldName}
        />
      )}
      {(confirmFormatDialogOpen && (
        <Dialog
          description={"Selecting this duration will erase you media files that don't match it"}
          title={'Media Duration Selection'}
          primaryButton={primaryButton}
          secondaryButton={secondaryButton}
        />
      )) ??
        null}
      {showMediaLibrary && (
        <MediaDialogLibraryLibrary
          resolution={resolution}
          formatId={uuid}
          uploadedmedia={uploadedmedia}
          contentType={contentType}
          duration={duration}
          onClose={() => setShowMediaLibrary(false)}
        />
      )}

      <ClassicCard
        otherClasses={`flex-1 p-6 md:h-100-76 overflow-y-auto flex mx-4 md:mx-0 ${
          activeMedia === uuid ? '' : 'hidden'
        }`}
      >
        <div className="flex-1 box-upload">
          <div
            id={index === 0 ? 'select-duration' : ''}
            className="px-4 py-2 mb-4 border border-dashed border-lightGrey dark:border-bordercolordark rounded-xl"
          >
            {activeMedia === uuid && (
              <div className="flex flex-row flex-wrap justify-between text-base dark:text-dark-400">
                <div className="flex flex-row flex-wrap items-center space-y-2 text-base dark:text-dark-400">
                  <p className="mr-4 whitespace-nowrap">Select media duration:</p>
                  <RadioBox
                    boxSize="big"
                    key={`${activeMedia}-${durationOptions[0]?.checked}-${cancelTrigger}`}
                    type="radio"
                    name={`primary_${activeMedia}`}
                    flexClass="flex-1 is-center-box"
                    onChange={(e: any) => {
                      const l = e.target.id.split('_');
                      setRadio(l[1]);
                    }}
                    options={durationOptions}
                  />
                </div>
                <div className="flex flex-row items-center mt-2 cursor-pointer" style={{ marginLeft: 'auto' }}>
                  <p
                    onClick={() => {
                      setShowLocationsList(!showLocationsList);
                    }}
                    className="text-base underline dark:text-dark-400 whitespace-nowrap"
                  >
                    {locationsName?.length} locations ({showLocationsList ? 'close' : 'open'})
                  </p>
                  {/* {locationsName.length > 1 && (
                      <p
                        onClick={() => {setShowLocationsList(!showLocationsList)}}
                        className='text-base underline text-primarydark-default'
                      >
                        Add custom media
                      </p>
                    )} */}
                </div>
              </div>
            )}
            {showLocationsList && (
              <div className="my-3 text-lg dark:text-dark-400">
                <p className="text-base">The following locations are controlled by this media format:</p>
                <p className="mb-4 text-sm dark:text-dark-300">
                  *each location has <span className="font-semibold dark:text-dark-400">{selectedDuration}s</span>{' '}
                  duration for the uploaded creatives
                </p>
                <div className="flex flex-wrap">
                  {locationsName &&
                    locationsName.map((location, indexLocation: number) => {
                      return (
                        <div className="mb-4" key={indexLocation}>
                          <Radio
                            name="warn"
                            justText={true}
                            color="primary"
                            onChange={(e: any) => {}}
                            labelColor="dark"
                            label={location}
                            value={location}
                            disabled={true}
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
          </div>
          <ReactTooltip id="supports-tooltip" place="top" className="custom-tooltip">
            <div className="preview-media-tooltip">
              <div>
                <span>Formats:</span>
                {contentType === ILocationContentType.Video ? (
                  <span className="pl-2">MP4, JPG, PNG</span>
                ) : (
                  <span className="pl-2"> JPG, PNG</span>
                )}
              </div>
              <div>
                <span>Resolution:</span>
                <span className="pl-2">
                  {resolution[0]} {'x'} {resolution[1]}
                </span>
              </div>
              {contentType === ILocationContentType.Video && (
                <div>
                  <span>Duration (for MP4):</span>
                  <span className="pl-2">{selectedDuration}s</span>
                </div>
              )}
            </div>
          </ReactTooltip>
          <Dropzone dropRef={dropRef} onMediaDrop={onMediaDrop} contentType={contentType}>
            <div
              id="drop-container"
              className="flex flex-col items-center justify-center h-full tracking-tighter text-center text-lightGrey"
            >
              <div className="mb-10">
                <img src="/images/media.svg" className="block dark:hidden" />
                <img src="/images/media-dark.svg" className="hidden dark:block" />
              </div>
              <div className="hidden text-xl font-semibold md:block dark:text-dark-500">
                Drag&amp;Drop your visual here, or{' '}
                <span
                  className="underline cursor-pointer text-primary-500"
                  onClick={() => {
                    dropRef.current?.click();
                  }}
                >
                  browse&nbsp;
                </span>
                to upload or{' '}
                <span
                  className="underline cursor-pointer text-primary-500"
                  onClick={() => {
                    setShowMediaLibrary(true);
                  }}
                >
                  add from media library
                </span>
              </div>
              <div className="text-lg font-semibold md:hidden">
                <span
                  className="underline cursor-pointer text-primary-500"
                  onClick={() => {
                    dropRef.current?.click();
                  }}
                >
                  Browse&nbsp;
                </span>
                or{' '}
                <span
                  className="underline cursor-pointer text-primary-500"
                  onClick={() => {
                    setShowMediaLibrary(true);
                  }}
                >
                  add from media library
                </span>{' '}
                to upload your visuals
              </div>
              <div className="flex flex-row items-center pt-1 space-x-2 text-base font-medium md:text-lg text-lightGrey md:text-body dark:text-dark-300">
                Supports:&nbsp;
                {contentType === ILocationContentType.Video ? (
                  <span className="dark:text-dark-400">MP4, JPG, PNG</span>
                ) : (
                  <span className="dark:text-dark-400"> JPG, PNG</span>
                )}
                <span className="pl-2 border-l border-bordercolor dark:border-bordercolordark dark:text-dark-400">
                  {resolution[0]} {'x'} {resolution[1]}
                </span>
                {contentType === ILocationContentType.Video && (
                  <span className="pl-2 border-l border-bordercolor dark:border-bordercolordark dark:text-dark-400">
                    {selectedDuration}s
                  </span>
                )}
                <div className="mr-4 cursor-pointer excl-mark" data-tip data-for="supports-tooltip">
                  !
                </div>
              </div>
            </div>
          </Dropzone>
          {hasNoDefault && (
            <div className="pt-3 pb-1 text-base font-medium text-primary-500">
              You must have at least one non-contextual visual!
            </div>
          )}
          {formatMedia.length > 0 && (
            <div id="uploaded-media" className="pt-3">
              <div className="mb-3 text-lg dark:text-dark-300">Live preview</div>
              <ul className="flex flex-wrap">
                {formatMedia.map((media: any, indexN: number) => {
                  return (
                    <li
                      key={`${media.uid}-preview-${indexN}`}
                      data-tip
                      data-for={`${media.oldName}-tooltip-${indexN}`}
                      className="mb-3 mr-3 preview-image"
                      onClick={() => onPreviewImage(media)}
                    >
                      <PreviewMediaCard data={media} resolution={resolution} />
                      <ReactTooltip id={`${media.oldName}-tooltip-${indexN}`} place="top" className="custom-tooltip">
                        <div className="preview-media-tooltip">{media.oldName}</div>
                      </ReactTooltip>
                    </li>
                  );
                })}
              </ul>
              <div className="flex items-center justify-start mt-5 mb-3 text-lg dark:text-dark-300">
                Uploaded media
                {formatMedia.filter(el => el.action === 'toupload').length > 0 && (
                  <div className="ml-3">
                    <StatusTag
                      hasCustomText={true}
                      text={
                        formatMedia.filter(el => el.action === 'toupload').length > 0
                          ? formatMedia.filter(el => el.action === 'toupload').length + ' uploading'
                          : ''
                      }
                      status="pending"
                      data-for="main"
                      data-tip="Text for high number of request"
                      data-iscapture="true"
                    />
                  </div>
                )}
                <div className="ml-3">
                  <StatusTag
                    hasCustomText={true}
                    text={
                      formatMedia.filter(el => el.action === 'isuploaded' || el.type.includes('draft')).length +
                      ' completed'
                    }
                    status="completed"
                    data-for="main"
                    data-tip="Text for high number of request"
                    data-iscapture="true"
                  />
                </div>
              </div>
              {formatMedia.map((el: IMediaUpload, indexN: number) => {
                return (
                  <MediaCard
                    rejectMedia={() => {
                      rejectMedia(el.uid);
                    }}
                    rejectMediaFaild={() => {
                      rejectMediaFaild(el.uid);
                    }}
                    previewMedia={() => {
                      onPreviewImage(el);
                    }}
                    updateVideoDuration={durationN => {
                      updateVideoDuration(el.uid, durationN);
                    }}
                    duration={duration}
                    uuid={uuid}
                    key={`${el.uid}-media-${indexN}`}
                    rules={rules}
                    resolution={resolution}
                    data={el}
                    ratio={1}
                    index={indexN}
                  />
                );
              })}
            </div>
          )}
        </div>
      </ClassicCard>
    </>
  );
};
export default MediaFormatDropzone;
