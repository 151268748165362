import React, { useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { useIntl, FormattedMessage } from 'react-intl';

import { PublishContext } from 'context/publish/publish.provider';
import ReactTooltip from 'react-tooltip';

import { useRouter } from 'next/router';

import {
  BreadCrumbs,
  FooterMobile,
  PublishMapFilter,
  PublishMapSection,
  PublishMapList,
  MediaFormat,
} from 'components/common/molecules';
import { IRulesApi } from 'interface';
import { ICampaignFormat } from 'interface/ILocation';
import { CAMPAIGNS } from 'api';
import { gtmCheckout4, gtmAddShippingInfo } from 'api/gtm/gtm';
import ClassicCard from 'components/common/atoms/ClassicCard';
import Slider from 'react-slick';
import MediaFormatDropzone from 'components/common/molecules/MediaFormatDropzone';
import { useApp } from 'components/app';
import useOnboardingContext from 'context/OnboardingContext';
import NewUploadMediaComponent from '../Monday/components/NewUploadMediaComponent/NewUploadMediaComponent';
type IProps = {
  rules: IRulesApi[];
  currency: string;
};
const Media: React.FC<IProps> = ({ rules, currency }: IProps) => {
  const { notify } = useApp();
  const intl = useIntl();
  const router = useRouter();
  const { publishState, dispatch } = React.useContext(PublishContext);
  const [format, setFormat] = React.useState<ICampaignFormat[]>();
  const [hasNext, setHasNext] = React.useState<boolean>(false);
  const [loadedMediaCounter, setLoadedMediaCounter] = React.useState<number>(0);
  const [activeMedia, setActiveMedia] = React.useState<string>('');

  const {
    setState,
    state: { run, tourActive, stepIndex, steps },
  } = useOnboardingContext();

  useEffect(() => {
    if (tourActive) {
        setState({ run: true, stepIndex: 17, steps, tourActive });
    }
  }, []);

  useEffect(() => {
    if (!publishState.locations || publishState.locations?.length === 0) {
      router.push(`/publish/select/?campaignId=${publishState.uuid}`);
    }
    return () => {};
  }, []);
  useEffect(() => {
    hasNextLink();
    if (publishState.formats && publishState.formats.length > 0) {
      setFormat(publishState.formats);
      setActiveMedia(publishState.formats[0].uuid);
    }
    return () => {};
  }, []);

  useEffect(() => {
    hasNextLink();
    if (publishState.formats && publishState.formats.length > 0) {
      setFormat(publishState.formats);
      let foundItem = publishState.formats.find((x: any) => x.uuid === activeMedia);
      if (foundItem) {
        setActiveMedia(activeMedia);
      } else {
        setActiveMedia(publishState.formats[0].uuid);
      }
    } else {
      backFn();
    }
    return () => {};
  }, [publishState.formats]);

  const backFn = () => {
    router.push(`/publish/schedule/?campaignId=${publishState.uuid}`);
  };
  const nextFn = () => {
    if (hasNext) {
      gtmAddShippingInfo(publishState, currency);
      gtmCheckout4();
      router.push(`/publish/review/?campaignId=${publishState.uuid}`);
    } else {
      notify(`Please make sure to load all media before pressing Continue.`, 'error');
    }
  };
  useEffect(() => {
    hasNextLink();
    return () => {};
  }, [publishState.toggleMedia]);

  useEffect(() => {
    CAMPAIGNS.saveCampaignDraft({ ...publishState, maxStep: 5 }).catch(err => console.log(err));
    return () => {};
  }, []);

  const hasNextLink = (): void => {
    let isUploading = false;
    for (const formatData of publishState.formats) {
      const f = formatData.media.filter(el => el.action === 'toupload');
      if (f.length > 0) {
        isUploading = true;
      }
    }

    let hasNextData = 0;
    for (const formatData of publishState.formats) {
      const f = formatData.media.filter(el => el.rules.length === 0);
      if (f.length !== 0) hasNextData += 1;
    }
    setLoadedMediaCounter(hasNextData);
    if (publishState.formats.length !== hasNextData || isUploading) setHasNext(false);
    else setHasNext(true);
  };

  const ref = useRef<any>(null);

  const calculateNewPosition = (pos: any) => {
    const newPosition = {
      top: window.innerWidth < 550 ? window.innerHeight - ref?.current?.offsetHeight - 200 : pos.top,
      left: window.innerWidth < 550 ? window.innerWidth - ref?.current?.offsetWidth - 35 : pos.left,
    };
    return newPosition;
  };

  const updateActiveMedia = (uuid: string) => {
    hasNextLink();
    setActiveMedia(uuid);
  };

  let settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    vertical: false,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          vertical: false,
          centerMode: true,
          arrows: false,
          slidesToShow: 1,
          centerPadding: '20px',
        },
      },
    ],
    afterChange: (index: any) => {
      updateActiveMedia(format ? format[index].uuid : '');
    },
  };

  return (
    <div className="lg:static page-section">
      <div className={`${run ? '' : 'overflow-y-auto'} md:h-screen pb-14`}>
        <div className="absolute top-0 left-0 right-0 flex items-center px-4 pl-4 pr-24 bg-white dark:bg-dark-200 dark:text-dark-400 md:hidden h-21 z-101 md:px-0 shadow-mob">
          Configure your visuals
        </div>
        <div className="mt-20 md:mt-0">
          <BreadCrumbs className="" />
          <div className="flex flex-col-reverse flex-wrap justify-between px-4 pt-4 md:py-6 md:space-x-7 md:flex-row md:px-7">
            <ClassicCard otherClasses="md:flex-1 flex items-center justify-between md:h-16 rounded-20 mt-4 md:mt-0">
              <div className="flex flex-row items-center w-full px-4 text-sm font-semibold tracking-tighter text-dark-default md:px-7 py-7 dark:text-dark-400">
                <div>
                  <div className="mr-4 cursor-pointer excl-mark ">!</div>
                </div>
                <div className="flex flex-row flex-wrap">
                  <span className="pr-2 mb-2 md:mb-0">Upload your visual for each media format.</span>
                  <span className="">We grouped your booked locations by type, accepted durations and resolution!</span>
                </div>
                <span
                  className={`media-status whitespace-nowrap order-4 ml-4 pl-4 border-l border-bordercolor dark:border-bordercolordark ${
                    loadedMediaCounter !== format?.length ? 'text-primary-500' : 'text-success-500'
                  }`}
                >
                  {loadedMediaCounter} / {format?.length} done
                </span>
              </div>
            </ClassicCard>
            <ClassicCard otherClasses="w-full md:w-auto h-21 md:h-16 flex justify-center">
              <PublishMapList source="media" />
            </ClassicCard>
          </div>
        </div>
        <div className="flex flex-col w-full px-0 space-y-8 overflow-hidden text-xl text-left md:px-7">
          {format && (
            <div className="flex flex-col space-y-4 md:flex-row md:space-y-0">
              <Slider {...settings} className={`md:w-80  md:hidden mt-8`}>
                {format.map((el, index) => {
                  return (
                    <MediaFormat
                      rules={rules}
                      uploadedmedia={el.media}
                      key={index}
                      uuid={el.uuid}
                      total={format.length}
                      index={index}
                      duration={el.selectedDuration}
                      durations={el.durations}
                      resolution={el.resolution}
                      contentType={el.contentType}
                      multiScreenUrl={el.multiScreenUrl}
                      locations={el.locationsName}
                      activeMedia={activeMedia}
                      changeMedia={updateActiveMedia}
                    />
                  );
                })}
              </Slider>

              <div  className="flex-col hidden pr-2 overflow-x-hidden overflow-y-auto md:flex h-100-76 md:mr-7">
                <div id={'mediaGroup'}>
                {format.map((el, index) => {
                  return (
                      <MediaFormat
                        rules={rules}
                        uploadedmedia={el.media}
                        key={index}
                        uuid={el.uuid}
                        total={format.length}
                        index={index}
                        duration={el.selectedDuration}
                        durations={el.durations}
                        resolution={el.resolution}
                        contentType={el.contentType}
                        multiScreenUrl={el.multiScreenUrl}
                        locations={el.locationsName}
                        activeMedia={activeMedia}
                        changeMedia={updateActiveMedia}
                      />
                      );
                    })}
                    </div>
            </div>

              {format.map((el, index) => {
                return (
                  <MediaFormatDropzone
                    contentType={el.contentType}
                    uploadedmedia={[...el.media]}
                    resolution={el.resolution}
                    rules={rules}
                    duration={el.selectedDuration}
                    durations={el.durations}
                    locationsName={el.locationsName}
                    uuid={el.uuid}
                    key={`${el.uuid}_${el.media.length}`}
                    activeMedia={activeMedia}
                    index={index}
                  ></MediaFormatDropzone>
                );
              })}
            </div>
          )}
        </div>
      </div>
      {(!(publishState.locations?.length !== 0) || !hasNext) && (
        <ReactTooltip
          id="footer-tooltip"
          className="custom-tooltip"
          place="left"
          overridePosition={calculateNewPosition}
        >
          <div ref={ref} className="flex flex-col preview-media-tooltip">
            <div className="flex">
              <div className="mr-2 excl-mark pulse-disclaimer">!</div>
              {!hasNext ? <span>Please make sure to load all media</span> : <span>No location booked</span>}
            </div>
          </div>
        </ReactTooltip>
      )}
      <FooterMobile
        backFn={backFn}
        nextFn={nextFn}
        mainPage="Dashboard"
        links={[publishState.name]}
        back="1"
        next="1"
        hasNext={publishState.locations && publishState.locations?.length > 0 && hasNext}
      />
    </div>
  );
};
export default Media;
