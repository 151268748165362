import React from 'react';
import { oneLine } from 'common-tags';
import { useIntl } from 'react-intl';

type IProps = {
  mainIcon?: string;
  secondIcon?: string;
  header: string;
  disclaimer?: string;
  isFirstLandscape?: boolean;
  noIcon?: boolean;
};

const MainMenuTooltip: React.FC<IProps> = ({
  mainIcon,
  secondIcon,
  header,
  disclaimer,
  isFirstLandscape,
  noIcon,
}: IProps) => {
  const extraDisclaimerClasses = oneLine`
    w-60
    text-center
    z-100
`;

  const intl = useIntl();
  return (
    <div className={`extra-disclaimer ${extraDisclaimerClasses}`}>
      {(!noIcon && (
        <div className="flex justify-center">
          <img
            src={mainIcon}
            width={isFirstLandscape ? '59' : '35'}
            height={isFirstLandscape ? '36' : '35'}
            className="mr-3"
          />
          <img
            src={secondIcon}
            width={!isFirstLandscape ? '59' : '35'}
            height={!isFirstLandscape ? '36' : '35'}
            className=""
          />
        </div>
      )) || <></>}

      <div className="z-100">
        <div className="text-black text-base font-bold pt-3 pb-2">{header}</div>
        <div className="text-sm text-body">{disclaimer}</div>
      </div>
    </div>
  );
};
export default MainMenuTooltip;
