import React from 'react';
import ClassicCard from './ClassicCard';

type PaginationProps = {
  currentPage: number;
  pageSize: number;
  totalItems: number;
  onPageChange: (page: number) => void;
};

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  pageSize,
  totalItems,
  onPageChange,
}) => {
  const totalPages = Math.ceil(totalItems / pageSize);

  if (totalPages <= 1) return null; 

  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbersToShow = 5;
    const totalPageNumbers = maxPageNumbersToShow + 2;

    if (totalPages <= totalPageNumbers) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      let startPage = Math.max(2, currentPage - 1);
      let endPage = Math.min(totalPages - 1, currentPage + 1);

      if (currentPage <= 3) {
        startPage = 2;
        endPage = 4;
      }

      if (currentPage >= totalPages - 2) {
        startPage = totalPages - 3;
        endPage = totalPages - 1;
      }

      pageNumbers.push(1); 

      if (startPage > 2) {
        pageNumbers.push('ellipsis-start');
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }

      if (endPage < totalPages - 1) {
        pageNumbers.push('ellipsis-end'); 
      }

      pageNumbers.push(totalPages);
    }

    return pageNumbers;
  };

  const pageNumbers = getPageNumbers();

  const handlePreviousPage = () => {
    if (currentPage > 1) onPageChange(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) onPageChange(currentPage + 1);
  };

  const isPageNumber = (value: number | string): value is number => typeof value === 'number';

  return (
    <ClassicCard otherClasses="px-5 py-4 my-4">
    <div className="flex justify-center">
      <nav className="inline-flex -space-x-px" aria-label="Pagination">
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className={`px-3 py-2 ml-0 leading-tight ${
            currentPage === 1 ? 'text-gray-400' : 'text-gray-500 dark:text-white hover:text-gray-700'
          } bg-white dark:bg-dark-200 border border-gray-300 rounded-l-lg hover:bg-gray-100`}
        >
          Previous
        </button>
        {pageNumbers.map((page, index) => {
          if (page === 'ellipsis-start' || page === 'ellipsis-end') {
            return (
              <span
                key={index}
                className="px-3 py-2 leading-tight text-gray-500 dark:text-white bg-white dark:bg-dark-200 border border-gray-300"
              >
                ...
              </span>
            );
          } else if (isPageNumber(page)) {
            return (
              <button
                key={index}
                onClick={() => onPageChange(page)}
                className={`px-3 py-2 leading-tight border border-gray-300 ${
                  page === currentPage
                    ? 'dark:text-white border-primary-600 hover:border-primary-700 z-101'
                    : 'text-gray-500 dark:text-white bg-white dark:bg-dark-200 hover:bg-gray-100 hover:text-gray-700'
                }`}
              >
                {page}
              </button>
            );
          }
          return null;
        })}
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className={`px-3 py-2 leading-tight ${
            currentPage === totalPages ? 'text-gray-400' : 'text-gray-500 dark:text-white hover:text-gray-700'
          } bg-white dark:bg-dark-200 border border-gray-300 rounded-r-lg hover:bg-gray-100`}
        >
          Next
        </button>
      </nav>
    </div>
    </ClassicCard>
  );
};

export default Pagination;
