import { IRadioBoxOptions } from 'interface/ISelect';
import { RadioBox } from '.';
import React, { useEffect } from 'react';
import ClassicCard from './ClassicCard';

type IProps = {
  otherClasses?: string;
};

const Appearance: React.FC<IProps> = ({ otherClasses }) => {

  const [mode, setMode] = React.useState<string | null>(null);
  const [themes, setThemes] = React.useState<IRadioBoxOptions[] | null>(null);

  const switchMode = (modeParam: string) => {
    const html = document.querySelector("html");
    if(modeParam === 'darkMode') {
      html?.classList.add("dark");
      localStorage.setItem('mode', 'darkMode');
    } else {
      html?.classList.remove("dark");
      localStorage.setItem('mode', 'lightMode');
    }
  }
  
  useEffect(() => {
    const modeStorage = localStorage.getItem('mode');
    setMode(modeStorage);
    setThemes([
      {
        value: 'darkMode',
        label: 'Dark Mode',
        checked: modeStorage ? (modeStorage === 'darkMode' ? true : false) : true
      },
      {
        value: 'lightMode',
        label: 'Light Mode',
        checked: modeStorage === 'lightMode' ? true : false
      }
    ])
  }, []);

  return <ClassicCard>
    <div className={`flex justify-between items-center p-5 ${otherClasses ? otherClasses : ''}`}>
      <p className='text-dark dark:text-white'>Appearance</p>
      {themes && (
        <div className='w-7/12'>
          <RadioBox
            boxSize="big"
            key={mode}
            type="radio"
            name="primary"
            flexClass="flex-1 is-center-box"
            onChange={(e: any) => {
              const modeTheme = e.target.id.split('_')[1];
              switchMode(modeTheme);
            }}
            options={themes}
          />
        </div>
      )}
      </div>
  </ClassicCard>
};
export default Appearance;
