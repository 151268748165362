import React, { useCallback, useState } from 'react';
import { Stack } from 'components/layout';
import { Button, Input, InputPhone, Header } from 'components/common/atoms';

import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import Link from 'next/link';
import { useApp } from 'components/app';
import { oneLine } from 'common-tags';
import { TPSAPI, JWT } from 'api';
import AuthContainer from './AuthContainer';

const ForgotPasswordForm: React.FC = () => {
  const intl = useIntl();
  const { notify, setUser } = useApp();

  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<number>(0);
  const [message, setMessage] = useState<string>('');
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(
        intl.formatMessage({
          defaultMessage: 'This is not a valid email address',
          id: 'Error.emailInvalid',
        })
      )
      .required(
        intl.formatMessage(
          {
            defaultMessage: 'Field is mandatory',
            id: 'Error.minimumLength',
          },
          { min: '2' }
        )
      ),
  });

  const useYupValidationResolver = (schema: any) =>
    useCallback(
      async (data: any) => {
        try {
          const values = await schema.validate(data, {
            abortEarly: false,
          });

          return {
            values,
            errors: {},
          };
        } catch (errors: any) {
          return {
            values: {},
            errors: errors.inner.reduce(
              (allErrors: any, currentError: any) => ({
                ...allErrors,
                [currentError.path]: {
                  type: currentError.type ?? 'validation',
                  message: currentError.message,
                },
              }),
              {}
            ),
          };
        }
      },
      [schema]
    );

  const resolver = useYupValidationResolver(validationSchema);
  const { register, handleSubmit,  formState, control } = useForm({ resolver });

  const onSubmit = async (data: any) => {
    setLoading(true);
    const response = await TPSAPI.recoverPassword(data.email);
    if (response.success === true) setSuccess(1);
    else {
      setSuccess(2);
      setMessage(response.message);
    }
    setLoading(false);
  };
  const { dirtyFields, errors } = formState;
  const getValidation = (inputName: any) => {
    const val = {
      isDirty: (dirtyFields as any)[inputName],
      error: (errors as any)[inputName],
    };
    return val;
  };

  return (
    <AuthContainer isRegister={true}>
      <Stack direction="horizontal" gravity="center" wrap={true} otherClass="tablet:w-full min-tablet:pr-16">
        <div className="md:max-w-screen-sm tablet:w-full">
          <div className="w-full px-10 py-10 bg-white dark:bg-dark-200 border-0 min-tablet:px-16 rounded-4xl mobile:rounded-b-none">
            {success === 1 ? (
              <div className="">
                <Stack direction="horizontal" spacing="xl" wrap={true} otherClass="text-center">
                  <Header
                    title="Reset password"
                    subtitle="Check your email for a link to reset your password. If it doesn’t appear within a few minutes, check your spam folder.'"
                  />
                  <div className="flex justify-center w-full text-center">
                    <Link href="/auth/login">
                      <Button type="button" color="primary">
                        {intl.formatMessage({
                          defaultMessage: 'Back to login',
                          id: 'Button.BackToLogin',
                        })}
                      </Button>
                    </Link>
                  </div>
                </Stack>
              </div>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="">
                  <Stack direction="horizontal" spacing="xl" wrap={true} otherClass="">
                    <Header
                      title="Reset password"
                      subtitle="Enter your email address and we will send you a link to reset your password."
                    />
                    <Stack fitX={true} padding={true} spacing="xl" direction="horizontal" wrap={true}>
                      <Input
                        name="email"
                        className="input-s"
                        innerRef={register}
                        validation={getValidation('email')}
                        type="email"
                        label="Email"
                        fullWidth={true}
                      />
                    </Stack>
                    <Stack fitX={true} wrap={true} gravity="center" otherClass="w-72 mx-auto mt-7">
                      <Button type="submit" color="primary" loading={loading} fullWidth>
                        {intl.formatMessage({
                          defaultMessage: 'Send password reset email',
                          id: 'Button.Sendpasswordresetemail',
                        })}
                      </Button>
                      {success === 2 && (
                        <div className={oneLine`text-danger-500 w-full text-xs  text-center}`}>
                          <p className="w-full text-center">{message}</p>
                        </div>
                      )}
                      {/* <div className="w-full text-center">
                      <Link href="/auth/login">
                      <a className="block pt-5 text-base font-semibold transition text-dark-500 hover:text-dark-600">
                          {intl.formatMessage({
                            defaultMessage: 'Back to login',
                            id: 'Button.BackToLogin'
                          })}
                        </a>
                      </Link>
                    </div> */}
                    </Stack>
                  </Stack>
                </div>
              </form>
            )}
          </div>
        </div>
      </Stack>
    </AuthContainer>
  );
};
export default ForgotPasswordForm;
