import { IRadioBoxOptions } from 'interface/ISelect';
import { RadioBox } from '.';
import React, { useEffect } from 'react';
import ClassicCard from './ClassicCard';
import { JWT, TPSAPI } from '@api/users';
import { ILoginResponse } from 'interface/user/IUserApi';
import { useApp } from 'components/app';

type IProps = {
  otherClasses?: string;
};

const OnboardingSwitch: React.FC<IProps> = ({ otherClasses }) => {

  const [hasOnboarding, setHasOnboarding] = React.useState<boolean>(true);
  const [options, setOptions] = React.useState<IRadioBoxOptions[] | null>(null);
  const { notify, setReloadJwt, reloadJwt } = useApp();

  const switchOnboarding = async (modeOnboarding: string) => {
      const response = await TPSAPI.updateOnboarding({
        sw: modeOnboarding === 'on' ? false : true
      });
  
      if (response.hasError === true) {
      } else {
        if (response.token) {
          JWT.setJwt(response.token as unknown as ILoginResponse);
          setReloadJwt(reloadJwt + 1);
        }
      }
  }
  
  useEffect(() => {
    const user = JWT.getJwtUser();
    if (JWT.getJwtUser()) {
      if(user.extra && user.emailValidation === true && ( Object.keys(user.extra).length === 0 || user.extra.hasOnboarding === false)) {
        setHasOnboarding(false);
      } else {
        setHasOnboarding(true);
      }
    }
  }, []);

  useEffect(() => {
    const user = JWT.getJwtUser();
    if (JWT.getJwtUser()) {
      if(user.extra && user.emailValidation === true && ( Object.keys(user.extra).length === 0 || user.extra.hasOnboarding === false)) {
        setHasOnboarding(false);
      } else {
        setHasOnboarding(true);
      }
    }
  }, [reloadJwt]);

  useEffect(() => { 
    setOptions([
      {
        value: 'off',
        label: 'Turn OFF',
        checked: hasOnboarding
      },
      {
        value: 'on',
        label: 'Turn ON',
        checked: !hasOnboarding
      }
    ])
  }, [hasOnboarding]);

  return <ClassicCard>
    <div className={`flex justify-between items-center p-5 ${otherClasses ? otherClasses : ''}`}>
      <p className='text-dark dark:text-white'>Onboarding Tour</p>
      {options && (
        <div className='w-7/12'>
          <RadioBox
            boxSize="big"
            key={String(options[0].checked)}
            type="radio"
            name="onboarding"
            flexClass="flex-1 is-center-box"
            onChange={(e: any) => {
              const modeOnboarding = e.target.id.split('_')[1];
              switchOnboarding(modeOnboarding);
            }}
            options={options}
          />
        </div>
      )}
      </div>
  </ClassicCard>
};
export default OnboardingSwitch;
