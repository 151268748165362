import React, { useState } from 'react'
import { CaretRight } from 'react-iconly'
import Slider from 'react-slick'
import AuthNavbar from './AuthNavbar'

type IProps = {
  isRegister: boolean,
  isReferral?: boolean,
  children: any
}

const AuthContainer: React.FC<IProps> = ({ isRegister, children, isReferral }: IProps) => {
  const customeSlider = React.useRef<Slider>(null);
  const [selectedSlide, setSelectedSlide] = useState<number>(1);

  let brendsImages = [
    "/images/brends1.png", "/images/brends2.png", "/images/brends3.png", "/images/brends4.png"
  ]

  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    afterChange: (index: any) => {
      setSelectedSlide(index + 1);
    },
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  let referralSliderItems = [
    {
      title: 'What is Blindspot?',
      textSlider: <div>Blindspot is a fully automated platform that makes it easy and affordable to advertise on <strong>over 1 Million digital billboards</strong> in the world.</div>,
      subTextSlider: <div>Over <strong>15.000 advertisers</strong> work with us. It’s your turn.</div>,
      textCard: 'Welcome to the best way of advertising on a digital billboard. We’re thrilled to have you and are here to make your experience spotless.',
      imgSrc: '/images/referral-about-blindspot.svg'
    },
    {
      title: 'Ready to get started? ',
      textSlider: <div>You can finish setting up your campaign in <strong>5 minutes or less</strong>. Check out <strong className='text-primarydark-600 underline'><a  rel="noopener noreferrer" href="https://seeblindspot.com/create-your-first-campaign-in-less-than-5-minutes-step-by-step/" target="_blank">this quick guide</a></strong> to get started!</div>,
      textCard: 'You can sign up using the form on this page and immediately start browsing locations and pricing. If you require assistance, just use the live chat option to get in touch with us!',
      imgSrc: '/images/referral-about-referral.svg'
    },
    {
      title: 'Do you need more information?',
      textSlider: <div>Reach out to <strong className='text-primarydark-600 underline'><a href = "mailto:hello@seeblindspot.com ">hello@seeblindspot.com</a></strong> or hop on our livechat - we'd love to hear from you!</div>,
      textCard: 
        <div>Want to learn more about best practices and Digital Out of Home (DOOH) advertising? Check out our <strong className="text-primarydark-600 underline"><a href="https://seeblindspot.com/resources/" rel="noreferrer" target="_blank">Resources page</a></strong></div>,
      imgSrc: '/images/referral-about-website.svg',
    }
  ]

  const handleClick = (index: number) => {
    (customeSlider.current as Slider).slickGoTo(index);
    setSelectedSlide(index + 1);
  };

  return (
    <div className={`h-screen flex flex-col ${!isReferral ? 'login-page' : ''}`}>
      <AuthNavbar isRegister={isRegister} />
      <div className="flex-1 flex items-center tablet:flex-col tablet:mt-20">
        {
          !isReferral &&
          <div className="flex-1">
            <img className="block dark:hidden tablet:hidden m-auto left-animation" src="/images/login-anim.png" />
            <img className="hidden dark:block dark:tablet:hidden tablet:hidden m-auto left-animation" src="/images/login-anim-darkmode.png" />
            <img className="hidden tablet:block m-auto left-animation" src="/images/login-anim-mobile.png" />
          </div>
        }
        {
          isReferral &&
          <div className="w-6/12 flex-wrap justify-center md:flex md:space-x-0 md:space-y-0 min-tablet:pl-16 space-y-0 tablet:w-full z-10 tablet:mb-10 my-10 min-tablet:my-0 order-last min-tablet:order-first">
            <div className="referral-container w-full px-10 tablet:px-7 border-0 min-tablet:px-16 rounded-4xl min-tablet:max-h-100-36 tablet:rounded-b-none">
              {referralSliderItems.map((slider, index) => {
                return (
                  <div key={index}
                    onClick={() => { handleClick(index) }}
                    className={`referral-step-container relative w-full flex flex-row items-center bg-white dark:bg-dark-200 rounded-2xl mb-10 px-4 py-6 
                      ${index + 1 === selectedSlide ? 'border border-primarydark-600' : 'cursor-pointer'}`
                    }>
                    <div className="referral-icon-container flex-shrink-0 rounded-full border border-dark-300 border-opacity-40 mx-4">
                      <img className="m-auto p-3" src={slider.imgSrc} />
                    </div>
                    <div className="dark:text-white px-2">
                      {slider.textCard}
                    </div>
                    {index + 1 !== selectedSlide && <div className="absolute top-0 left-0 w-full h-full bg-f3f3f3 dark:bg-dark-200 rounded-2xl bg-opacity-40 dark:bg-opacity-80"></div>}
                  </div>
                );
              })}
            </div>
            <div className="w-full px-16 relative">
              <Slider ref={customeSlider} {...settings} className={``}>
                {referralSliderItems.map((sliderItem, index) => {
                  return (
                    <div
                      key={index}
                      className="flex flex-col items-center pb-10 h-full"
                      onClick={() => { handleClick(index); }}
                    >
                      <h1 className="dark:text-white font-semibold mb-3 text-center">{sliderItem.title}</h1>
                      <h3 className="dark:text-dark-300 text-center">{sliderItem.textSlider}</h3>
                      {sliderItem.subTextSlider &&
                        <div className="mt-3 flex flex-row items-center justify-center flex-wrap">
                          <div className="flex flex-row mr-3 mb-3">
                            {brendsImages.map((el) => {
                              return (
                                <img className="-ml-3" src={el} />
                              )
                            })}
                          </div>
                          <h3 className="dark:text-dark-300 text-center mb-3">{sliderItem.subTextSlider}</h3>
                        </div>
                      }
                    </div>
                  );
                })}
              </Slider>
              <div className="absolute bottom-0 right-0 tablet:hidden w-20">
                <img src='/images/arrow-referral.svg' />
              </div>
            </div>
          </div>
        }
        <div className="flex-1 tablet:w-full">{children}</div>
      </div>
    </div >
  )
}
export default AuthContainer
