import React, { useEffect, useRef, useState } from 'react';
import { Stack } from 'components/layout';
import { Button, Input, InputPhone, Header } from 'components/common/atoms';

import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import Link from 'next/link';
import { useApp } from 'components/app';
import dynamic from 'next/dynamic';
import { TPSAPI, JWT } from 'api';
import AuthContainer from './AuthContainer';
import { ILoginResponse } from 'interface/user/IUserApi';
import { useRouter } from 'next/router';
import { gtmRegisterUser } from '@api/gtm/gtm';
import { destroyCookie } from 'nookies';

const ReactCodeInput = dynamic(import('react-code-input'));

const ValidateEmailForm: React.FC = () => {
  const intl = useIntl();
  const { notify } = useApp();
  const [user] = React.useState(JWT.getJwtUser());
  const router = useRouter();

  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<number>(0);
  const [code, setCode] = useState<string>(router && router.query.id ? router.query.id.toString() : '');
  const [message, setMessage] = useState<string>('');
  const [countDownTimmer, setCountDownTimmer] = useState<number>(60);
  const count = useRef<number>(60);
  const timer = useRef<any>(null);

  const countDown = () => {
    count.current = 60;
    if (timer.current) clearInterval(timer.current);
    timer.current = setInterval(() => {
      count.current--;
      if (count.current < 0) count.current = 0;

      setCountDownTimmer(count.current);
      if (count.current === 0) {
        clearInterval(timer.current);
      }
    }, 1000);
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(
        intl.formatMessage({
          defaultMessage: 'This is not a valid email address',
          id: 'Error.emailInvalid',
        })
      )
      .required(
        intl.formatMessage(
          {
            defaultMessage: 'Field is mandatory',
            id: 'Error.minimumLength',
          },
          { min: '2' }
        )
      ),
  });

  const { register, handleSubmit,  formState, control } = useForm();

  const onSubmit = async () => {
    setLoading(true);
    const response = await TPSAPI.validateCodeEmail(code);
    if (response.hasError !== true) {
      let r: ILoginResponse = {
        hasError: false,
        errorMessage: '',
        accessToken: response.accessToken,
        refreshToken: response.refreshToken,
        expiresIn: response.expiresIn,
      };
      const responseData = JWT.setJwt(r);
      gtmRegisterUser();
      destroyCookie(null, 'blindspotReferralCode');
      router.push('/');
    } else {
      notify('Invalid code', 'error', 'top-right');
      setSuccess(2);
      setMessage(response.errorMessage);
    }
    setLoading(false);
  };
  const { dirtyFields, errors } = formState;
  const getValidation = (inputName: any) => {
    const val = {
      isDirty: (dirtyFields as any)[inputName],
      error: (errors as any)[inputName],
    };
    return val;
  };

  const resendCode = async () => {
    const response = await TPSAPI.resendCode(code);
    countDown();
  };
  useEffect(() => {
    countDown();
    return () => {
      count.current = 60;
    };
  }, []);
  return (
    <AuthContainer isRegister={false} isReferral={true}>
      <Stack
        direction="horizontal"
        gravity="center"
        wrap={true}
        otherClass="tablet:w-full tablet:w-full min-tablet:pr-16"
      >
        <div className="md:max-w-screen-sm tablet:w-full">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="w-full px-10 py-10 bg-white border-0 dark:bg-dark-200 min-tablet:px-16 rounded-4xl">
              <Stack direction="horizontal" spacing="none" wrap={true}>
                <div
                  className="flex flex-row items-center mb-3 cursor-pointer dark:text-dark-300 text-body validate-back dark:hover:text-white"
                  onClick={() => router.back()}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="9" viewBox="0 0 12 9" fill="none">
                    <path
                      d="M10.5833 4.49992L1.41665 4.49992M1.41665 4.49992L5.24018 7.83325M1.41665 4.49992L5.24018 1.16659"
                      stroke="#E4DEDE"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <h1 className="ml-2">Back</h1>
                </div>
                <Header
                  title="Confirmation code"
                  subtitle={`We've sent you a code to validate your email address ${user.email}. Check your inbox or your spam!`}
                />
                <Stack fitX={true} gravity="center" align='center' otherClass="flex">
                  <ReactCodeInput
                    onChange={(v: string) => {
                      setCode(v);
                    }}
                    name="code"
                    inputMode="numeric"
                    type="number"
                    fields={6}
                    value={code}
                  />
                </Stack>
                <Stack fitX={true} gravity="center" otherClass="mt-7 flex">
                  <h1 className="mr-2 dark:text-dark-300 text-body">Didn't get a code?</h1>
                  {countDownTimmer === 0 ? (
                    <h1 onClick={resendCode} className="font-semibold cursor-pointer dark:text-white">
                      Resend validation code
                    </h1>
                  ) : (
                    <h1 className="font-semibold dark:text-dark-300 text-body">Resend in {countDownTimmer} seconds</h1>
                  )}
                </Stack>
                <Stack fitX={true} wrap={true} gravity="center" otherClass="w-72 mx-auto mt-7">
                  <Button type="submit" color="primary" loading={loading} fullWidth={true}>
                    Confirm code
                  </Button>
                </Stack>
              </Stack>
            </div>
          </form>
        </div>
      </Stack>
    </AuthContainer>
  );
};
export default ValidateEmailForm;
