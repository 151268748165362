import React, { forwardRef, useEffect, useRef, useState } from 'react';

import NetflixTooltip from '../molecules/NetflixTooltip';
import { ILocationFull, INetflix } from 'interface/ILocation';
import { CAMPAIGNS } from 'api/';
import Slider from '../molecules/Slider/index';
import SliderItem from '../molecules/Slider/Item';
import { Carousel } from '@utils/types';
import { ClassicSlider, Slider3d } from '../molecules';

type IProps = {
  title?: string;
  index: any;
  type?: Carousel;
  otherClasses?: string;
  locations: INetflix[];
  category?: string;
};

const Netflix: React.FC<IProps> = ({ title, index, otherClasses, type, locations, category }: IProps) => {
  const [item, setItem] = useState<any>(null);
  const [focusedLocation, setFocusedLocation] = React.useState<ILocationFull | null>(null);

  useEffect(() => {
    if (item) {
      getLocation(item);
    } else {
      setFocusedLocation(null);
    }
  }, [item]);
  const onCloseTooltip = () => {
    setFocusedLocation(null);
    setItem(null);
  };
  const getLocation = async (location: string) => {
    try {
      const response = await CAMPAIGNS.getLocationById(location);
      if (response.status && response.locations) {
        setFocusedLocation(response.locations);
      }
    } catch (error) { }
  };
  const openItem = (uuid: string) => {
    setItem(uuid);
  };

  const carousels = () => {
    switch (type) {
      case '3d':
        return <Slider3d otherClasses={(otherClasses && otherClasses) || ''} sliderItems={locations} category={category} />;

      case 'full-center':
        return <ClassicSlider sliderItems={locations} fullCenter={true} category={category} />;

      default:
        return <ClassicSlider sliderItems={locations} category={category} />;
    }
  };

  return (
    <div id="sliderTopSeller" className={`pb-7`}>
      {title ? <h3 className="pb-6 text-lg font-bold text-black dark:text-white lg:text-xl pl-7">{title}</h3> : ''}
      {carousels()}
    </div>
  );
};
export default Netflix;
