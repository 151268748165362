import React, { useCallback, useState } from 'react';
import { Stack } from 'components/layout';
import { Button, Input, InputPhone, Header } from 'components/common/atoms';
import nookies from 'nookies';

import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useIntl, FormattedMessage } from 'react-intl';
import Link from 'next/link';
import { useApp } from 'components/app';
import { useRouter } from 'next/router';
import { TPSAPI, JWT } from 'api';
import AuthNavbar from './AuthNavbar';
import AuthContainer from './AuthContainer';
import SyncGoogleAccount from 'components/common/molecules/SyncGoogleAccount';

const LoginForm: React.FC = () => {
  const intl = useIntl();
  const router = useRouter();

  const queryParam = router.query;
  const [loading, setLoading] = useState<boolean>(false);
  const { notify, setUser } = useApp();
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(
        8,
        intl.formatMessage(
          {
            defaultMessage: 'The field needs to be at least {min} characters long',
            id: 'Error.minimumLength',
          },
          { min: '8' }
        )
      )
      .required(
        intl.formatMessage({
          defaultMessage: 'Field is mandatory',
          id: 'Error.mandatoryField',
        })
      ),

    email: Yup.string()
      .email(
        intl.formatMessage({
          defaultMessage: 'This is not a valid email address',
          id: 'Error.emailInvalid',
        })
      )
      .required(
        intl.formatMessage(
          {
            defaultMessage: 'Field is mandatory',
            id: 'Error.minimumLength',
          },
          { min: '2' }
        )
      ),
  });

  const useYupValidationResolver = (schena: any) =>
    useCallback(
      async (data: any) => {
        try {
          const values = await validationSchema.validate(data, {
            abortEarly: false,
          });

          return {
            values,
            errors: {},
          };
        } catch (errors: any) {
          return {
            values: {},
            errors: errors.inner.reduce(
              (allErrors: any, currentError: any) => ({
                ...allErrors,
                [currentError.path]: {
                  type: currentError.type ?? 'validation',
                  message: currentError.message,
                },
              }),
              {}
            ),
          };
        }
      },
      [schena]
    );

  const resolver = useYupValidationResolver(validationSchema);
  const { register, handleSubmit, formState, errors, control } = useForm({ resolver });
  const onSubmit = async (data: any) => {
    setLoading(true);
    const response = await TPSAPI.signin({
      email: data.email,
      password: data.password,
    });
    setLoading(false);
    if (response.hasError === true) {
      notify(response?.errorMessage, 'error');
    } else {
      const responseData = JWT.setJwt(response);

      if (responseData.emailValidation === false) {
        router.push('/auth/validate-email');
      } else {
        const cookies = nookies.get();
        nookies.destroy(null, 'redirect', { path: '/' });

        router.push(cookies.redirect || '/');
      }
      setUser(responseData);
    }
  };

  const { dirtyFields } = formState;
  const getValidation = (inputName: any) => {
    const val = {
      isDirty: (dirtyFields as any)[inputName],
      error: (errors as any)[inputName],
    };
    return val;
  };

  const cookies = nookies.get();

  return (
    <AuthContainer isRegister={false}>
      <Stack direction="horizontal" gravity="center" wrap={true} otherClass="tablet:w-full min-tablet:pr-16">
        <div className="md:max-w-screen-sm tablet:w-full">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="w-full px-10 py-10 bg-white border-0 dark:bg-dark-200 min-tablet:px-16 rounded-4xl tablet:rounded-b-none">
              <Stack direction="horizontal" spacing="none" wrap={true}>
                <Header
                  title="Login to your account"
                  subtitle="Welcome back! Please enter your details below to Login."
                />
                <Stack fitX={true}>
                  <Input
                    name="email"
                    innerRef={register}
                    validation={getValidation('email')}
                    type="email"
                    className="input-s"
                    label="Email"
                    fullWidth={true}
                  />
                </Stack>
                <Stack fitX={true}>
                  <Input
                    name="password"
                    className="input-s"
                    validation={getValidation('password')}
                    innerRef={register}
                    fullWidth={true}
                    type="password"
                    showStrength={false}
                    label="Password"
                  />
                </Stack>
                <input
                  type="hidden"
                  name="ga4id"
                  id="ga4id"
                  value={(queryParam?.ga4id as string) || cookies['_ga']?.split('.').slice(-2).join('.') || ''}
                />
                <input
                  type="hidden"
                  name="gclid"
                  id="gclid"
                  value={(queryParam?.gclid as string) || cookies['gclidCookie']}
                />

                <div className="w-full text-right min-tablet:hidden">
                  <Link href="/auth/forgot-password">
                    <a className="block pb-2 text-base font-semibold transition text-primary-500 hover:text-primary-600">
                      {intl.formatMessage({
                        defaultMessage: 'Lost your password?',
                        id: 'Button.LostYourPassword',
                      })}
                    </a>
                  </Link>
                </div>
                <Stack fitX={true} wrap={true} gravity="center" otherClass="w-64 mx-auto mt-7">
                  <Button type="submit" color="primary" loading={loading} fullWidth>
                    {intl.formatMessage({
                      defaultMessage: 'Sign in',
                      id: 'Button.SIGNIN',
                    })}
                  </Button>

                  <div className="w-full text-center tablet:hidden">
                    <Link href="/auth/forgot-password">
                      <a className="block pt-5 text-base font-semibold transition text-dark-500 dark:text-dark-400 hover:text-dark-600">
                        {intl.formatMessage({
                          defaultMessage: 'Lost your password?',
                          id: 'Button.LostYourPassword',
                        })}
                      </a>
                    </Link>
                  </div>
                </Stack>
                <Stack fitX={true} wrap={true} spacing="none" direction="horizontal" padding={true}>
                  <SyncGoogleAccount></SyncGoogleAccount>
                </Stack>
                <div className="flex items-center justify-center w-full pt-5 shadow-top-border min-tablet:hidden">
                  <h4 className="pr-3 text-base font-semibold text-dark-500 dark:text-dark-400">
                    {intl.formatMessage({
                      defaultMessage: 'New to Blindspot?',
                      id: 'Text.NewToBlindspot',
                    })}
                  </h4>
                  <div className="text-center">
                    <Link href="/auth/register">
                      <a className="text-base font-semibold transition text-primary-500 hover:text-primary-500">
                        {intl.formatMessage({
                          defaultMessage: 'Create an account',
                          id: 'Button.Register',
                        })}
                      </a>
                    </Link>
                  </div>
                </div>
              </Stack>
            </div>
          </form>
        </div>
      </Stack>
    </AuthContainer>
  );
};
export default LoginForm;
