import React, { useCallback, useState } from 'react';
import * as Yup from 'yup';
import { useIntl, FormattedMessage } from 'react-intl';

import { Header, Input, Button } from 'components/common/atoms';
import { Stack } from 'components/layout';
import { IMyUserInfo } from 'interface';
import Link from 'next/link';
import { Controller, useForm } from 'react-hook-form';
import { oneLine } from 'common-tags';
import { JWT, TPSAPI } from 'api';
import { useApp } from 'components/app';
import zxcvbn from 'zxcvbn';
import Avatar from '../Avatar';

type ProfileProps = {
  user: IMyUserInfo;
  isGoogle: boolean;
};

const ProfileSecurity: React.FC<ProfileProps> = ({ user, isGoogle }: ProfileProps) => {
  const intl = useIntl();
  const { notify, setUser } = useApp();

  const validationSchema = Yup.object().shape({
    password: isGoogle
      ? Yup.string().nullable()
      : Yup.string()
          .min(
            8,
            intl.formatMessage(
              {
                defaultMessage: 'The field needs to be at least {min} characters long',
                id: 'Error.minimumLength',
              },
              { min: '8' }
            )
          )
          .required(
            intl.formatMessage({
              defaultMessage: 'Field is mandatory',
              id: 'Error.mandatoryField',
            })
          ),
    newpassword: Yup.string()
      .min(
        8,
        intl.formatMessage(
          {
            defaultMessage: 'The field needs to be at least {min} characters long',
            id: 'Error.minimumLength',
          },
          { min: '8' }
        )
      )
      .test(
        'test-name',
        intl.formatMessage({
          defaultMessage: "The password doesn't meet the minimum requirements",
          id: 'Error.passwordMinimunRequirements',
        }),
        value => {
          if (value) {
            return zxcvbn(value).score >= 2;
          } else {
            return false;
          }
        }
      )
      .required(
        intl.formatMessage({
          defaultMessage: 'Field is mandatory',
          id: 'Error.mandatoryField',
        })
      ),
  });

  const useYupValidationResolver = (schema: any) =>
    useCallback(
      async (data: any) => {
        try {
          const values = await schema.validate(data, {
            abortEarly: false,
          });

          return {
            values,
            errors: {},
          };
        } catch (errors: any) {
          return {
            values: {},
            errors: errors.inner.reduce(
              (allErrors: any, currentError: any) => ({
                ...allErrors,
                [currentError.path]: {
                  type: currentError.type ?? 'validation',
                  message: currentError.message,
                },
              }),
              {}
            ),
          };
        }
      },
      [schema]
    );
  const [useData, setUserData] = React.useState<IMyUserInfo>(user);
  const [loading, setLoading] = useState<boolean>(false);
  const [showValidationField, setShowValidationField] = useState<boolean>(
    user.newEmail && user.newEmail !== '' ? true : false
  );
  const [validationFieldValue, setValidationFieldValue] = useState<string>('');
  const resolver = useYupValidationResolver(validationSchema);
  const { register, handleSubmit,  formState, control } = useForm({ resolver });

  const onSubmit = async (data: any) => {
    setLoading(true);
    const response = await TPSAPI.changePassword(data.password, data.newpassword);
    setLoading(false);
    if (response.hasError === true) {
      notify(response.message || "", 'error');
    } else {
      notify(response.message  || "", 'success');
      if (response.redirect === true) {
        setShowValidationField(true);
      }
    }
  };
  const { dirtyFields,errors } = formState;
  const getValidation = (inputName: any) => {
    const val = {
      isDirty: (dirtyFields as any)[inputName],
      error: (errors as any)[inputName],
    };
    return val;
  };
  return (
    <div className="flex flex-col justify-evenly">
      <div className="pb-8">
        <Avatar starter={JWT.getJwtUser().avatar || '{}'} />
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={oneLine`max-w-full md:max-w-2xl mx-auto`}>
          <Stack direction="horizontal" spacing="none" wrap={true} otherClass="space-y-8">
            {!isGoogle && (
              <Input
                name="password"
                validation={getValidation('password')}
                innerRef={register}
                fullWidth={true}
                className="input-s"
                type="password"
                showStrength={false}
                label={intl.formatMessage({
                  defaultMessage: 'Old Password',
                  id: 'Label.OldPassword',
                })}
              />
            )}
            <Input
              name="newpassword"
              className="input-s"
              validation={getValidation('newpassword')}
              innerRef={register}
              fullWidth={true}
              type="password"
              showStrength={true}
              label={intl.formatMessage({
                defaultMessage: 'New Password',
                id: 'Label.NewPassword',
              })}
            />

            <div className="w-full mx-auto max-w-15">
              <Button type="submit" color="tertiary" loading={loading} fullWidth={true}>
                {intl.formatMessage({
                  defaultMessage: 'Save',
                  id: 'Button.Save',
                })}
              </Button>
            </div>
          </Stack>
        </div>
      </form>
    </div>
  );
};
export default ProfileSecurity;
