import React, { useCallback, useState } from 'react'
import { Stack } from 'components/layout'

import { useIntl, FormattedMessage } from 'react-intl'
import Link from 'next/link'
import { useRouter } from 'next/router'

type IProps = {
  isRegister: boolean
}

const AuthNavbar: React.FC<IProps> = ({ isRegister }: IProps) => {
  const intl = useIntl()
  const router = useRouter()
  return (
    <div className="h-24 flex-shrink-0">
      <div className="flex justify-between items-center h-full px-16">
        <div className="w-40 pt-12">
          <img src="/images/logo-blindspot.svg" width="280" height="115" alt="Blindspot" className="block dark:hidden pt-10" />
          <img src="/images/logo-blindspot-darkmode.svg" width="280" height="115" alt="Blindspot" className="hidden dark:block pt-10" />
          <div className='flex flex-row flex-nowrap pb-10 item-center'>
            <div className='flex items-center'>
              <p className="formerly pr-1">Formerly</p>
            </div>
            <div>
              <img src="/images/logo-tps.png" width="60" alt="TPSEngage" className="block" />
            </div>
          </div>
        </div>
        <div className="tablet:hidden">
          <Stack fitY={true} wrap={true} direction="vertical" otherClass="shadow-top-border pb-4 pt-4 w-full">
            <h4 className="text-base font-semibold text-dark-500 dark:text-dark-400 pr-3">
              {isRegister
                ? intl.formatMessage({
                    defaultMessage: 'Already have an account',
                    id: 'Text.HasAccount'
                  })
                : intl.formatMessage({
                    defaultMessage: 'New to Blindspot?',
                    id: 'Text.NewToBlindspot'
                  })}
            </h4>
            <div className="text-center">
              {isRegister ? (
                <Link href="/auth/login">
                  <a className="text-base font-semibold text-primary-500 hover:text-primary-500 transition">
                    {intl.formatMessage({
                      defaultMessage: 'Login',
                      id: 'Text.Login'
                    })}
                  </a>
                </Link>
              ) : (
                <Link href="/auth/register">
                  <a className="text-base font-semibold text-primary-500 hover:text-primary-500 transition">
                    {intl.formatMessage({
                      defaultMessage: 'Create an account',
                      id: 'Button.Register'
                    })}
                  </a>
                </Link>
              )}
            </div>
          </Stack>
        </div>
      </div>
    </div>
  )
}
export default AuthNavbar
