import React, { useEffect, useState } from 'react';

import { DateTime } from 'luxon';
import styled from 'styled-components';
import { groupBy, chain, map } from 'lodash';
import dynamic from 'next/dynamic';

import { CAMPAIGNS } from 'api/';
import { IPerformance } from 'interface';
import { Button } from '../atoms';
import numeral from 'numeral';

const LayerdColumnsReach = dynamic(() => import('../atoms/LayerdColumnsReach'), { ssr: false });

type IProps = {
  chartTitle: string;
  screen: string;
  uuid: string;
  tz: string;
  filterType: string;
  type?: string;
};
const AmTrafficChart = ({ chartTitle, screen, uuid, tz, filterType, type }: IProps) => {
  // const [filterType, setFilterType] = useState('day')
  const [chartData, setChartData] = React.useState<IPerformance[]>([]);
  const [total, setTotal] = React.useState<number>(0);
  useEffect(() => {
    fetchData();
    return () => {};
  }, [filterType, screen]);

  const fetchData = async () => {
    if (uuid) {
      setChartData([]);

      const result =
        type && type === 'group'
          ? await CAMPAIGNS.getPerformanceGroupReach(uuid, tz, filterType, screen)
          : await CAMPAIGNS.getPerformanceReach(uuid, tz, filterType, screen);
      if (result.performance) {
        let h = result.performance.reduce((acc: number, el) => {
          return acc + el.reach;
        }, 0);
        setTotal(h);
        setChartData(result.performance);
      }
    }
  };

  return (
    <div className="w-full p-6 space-y-5 rounded-2xl bg-chartBgGrey dark:bg-dark-200 dark:border-2 dark:border-bordercolordark dark:border-opacity-40 rounded-2xl p-6">
      <div className={`flex flex-col ${total ? '' : 'chart-no-data'}`}>
        <h3 className="text-base dark:text-dark-400 font-semibold tracking-tighter  md:text-sm text-chartTitleGrey">
          {chartTitle}
        </h3>
        <div className="pt-2 text-lg dark:text-white font-bold text-dark-default md:text-2xl">
          {numeral(total).format('0,0')}
        </div>
      </div>

      {total > 0 ? (
        <div className="">
          {chartData && chartData.length > 0 ? (
            <LayerdColumnsReach chartData={chartData} isHourlyDisplay={filterType === 'hour'}></LayerdColumnsReach>
          ) : (
            <div />
          )}
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center">
          <img src="/images/audience.png" className="max-w-sm" loading="lazy" />
          <h3 className="py-5 text-chartTitleGrey dark:text-white text-sm font-semibold">
            This location does not provide impression data.
          </h3>
        </div>
      )}
    </div>
  );
};
export default AmTrafficChart;
