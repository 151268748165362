import React, { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useIntl, FormattedMessage } from 'react-intl';

import { TPSAPI } from 'api/users';

import { Header, Input, Button, SelectInput, AddCardDialogStripe, AddCardDialog } from 'components/common/atoms';
import { Stack } from 'components/layout';
import { IUserCard, IUserInvoice, IUserOrganization } from 'interface';
import { ListCards } from 'components/common/molecules';
import ClassicCard from 'components/common/atoms/ClassicCard';
import { destroyCookie } from 'nookies';

type ProfileProps = {
  cards: IUserCard[];
};

const ProfileCards: React.FC<ProfileProps> = ({ cards }: ProfileProps) => {
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const [newCards, setNewCards] = React.useState<IUserCard[]>(cards);
  const [updateCardList, setUpdateCardList] = React.useState<number>(0);
  const [user, setUser] = React.useState<IUserOrganization | null>(null);
  useEffect(() => {
    getUser();
  }, []);
  const getUser = async () => {
    const User = await TPSAPI.getUserCompanyData();
    setUser(User);
  };
  const onCardSubmitSuccess = async () => {
    setDialogOpen(false);
    const useData: IUserCard[] = await TPSAPI.getUserPayment();
    destroyCookie(null, 'invalid_payment_methods');
    setNewCards(useData);
    setUpdateCardList(updateCardList + 1);
  };
  return (
    <>
      <ClassicCard otherClasses="flex w-full p-7 h-full">
        {newCards.length === 0 ? (
          <div className="flex items-center w-full ">
            <div className="mx-auto space-y-8 text-center ">
              <h3 className="text-xl font-medium text-body font-display dark:text-dark-400">
                You have no payment methods.
              </h3>
              <p className="dark:text-dark-400">In order to create a campaign you must add a primary payment method.</p>
              <div>
                <Button
                  onClick={() => {
                    setDialogOpen(true);
                  }}
                  color="tertiary"
                  className="mx-auto"
                >
                  Add payment method
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div className="w-full ">
            <div className="w-full space-y-5">
              <div className="flex items-center justify-between">
                <h3 className="text-xl font-bold tracking-tighter text-body dark:text-white">Cards</h3>
                <div>
                  <Button
                    onClick={() => {
                      setDialogOpen(true);
                    }}
                    color="primary"
                    fill="none"
                    className="p-0 mx-auto underline"
                  >
                    Add new payment method
                  </Button>
                </div>
              </div>
              <ListCards
                key={`${newCards.length}-${updateCardList}`}
                cards={newCards}
                getCards={async () => {
                  await onCardSubmitSuccess();
                }}
              />
            </div>
          </div>
        )}
      </ClassicCard>
      {user && dialogOpen && (
        <>
          <AddCardDialogStripe
            user={user}
            onCardSubmitSuccess={onCardSubmitSuccess}
            open={dialogOpen}
            closePopup={() => setDialogOpen(false)}
          />
        </>
      )}
    </>
  );
};
export default ProfileCards;
