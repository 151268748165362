import React, { useEffect, useState } from 'react';
import RadioBox from '../atoms/RadioBox';
import { useRouter } from 'next/router';
import { ICampaignList } from 'interface/ICampaign';
import { oneLine } from 'common-tags';
import ClassicCard from '../atoms/ClassicCard';
import SearchBar from '../molecules/Search';
import { FilterCampaignList } from '../molecules';
import { DisplayGrid, DisplayList } from '.';
import Pagination from '../atoms/Pagination'; 

type IProps = {
  drafts: ICampaignList[] | null | undefined;
  ownCampaigns: ICampaignList[] | null | undefined;
  bulkCampaigns: ICampaignList[] | null | undefined;
  pagination: {
    pageDrafts: number;
    pageSizeDrafts: number;
    totalDrafts: number;
    pageOwn: number;
    pageSizeOwn: number;
    totalOwn: number;
    pageBulk: number;
    pageSizeBulk: number;
    totalBulk: number;
  };
  query: any;
};

const MyCampaigns: React.FC<IProps> = ({
  drafts,
  ownCampaigns,
  bulkCampaigns,
  pagination,
  query,
}: IProps) => {
  const router = useRouter();
  const [options, setOptions] = useState<any>([
    { value: 'grid', icon: 'Category', label: '', checked: true },
    { value: 'list', icon: 'Filter', label: '', checked: false },
  ]);

  const [displayType, setDisplayType] = useState<string>(query.displayType || 'grid');

  useEffect(() => {
    const updatedOptions = options.map((option: any) => ({
      ...option,
      checked: option.value === displayType,
    }));
    setOptions(updatedOptions);
  }, [displayType]);

  const updateQueryParams = (params: { [key: string]: string }) => {
    const newQuery = { ...query, ...params };
    router.push(
      {
        pathname: router.pathname,
        query: newQuery,
      },
      undefined,
      { shallow: false, scroll: false }
    );
  };

  const handleSearchTextChange = (text: string) => {
    updateQueryParams({ q: text, pageDrafts: '1', pageOwn: '1', pageBulk: '1' });
  };

  const handleFiltersChange = (statuses: string[], campaignTypes: string[]) => {
    updateQueryParams({
      filters: statuses.join(','),
      campaignType: campaignTypes.join(','),
      pageDrafts: '1',
      pageOwn: '1',
      pageBulk: '1',
    });
  };

  const handlePageChange = (category: 'drafts' | 'own' | 'bulk', newPage: number) => {
    const pageParam = category === 'drafts' ? 'pageDrafts' : category === 'own' ? 'pageOwn' : 'pageBulk';
    updateQueryParams({ [pageParam]: newPage.toString() });
  };

  const deleteCallback = (id: string) => {
    router.replace(router.asPath, undefined, { scroll: false });
  };

  return (
    <>
      <ClassicCard otherClasses="px-5 py-4 mb-7">
        <div className={oneLine`w-full flex items-center relative z-50 justify-between`}>
          <div className="flex items-center justify-between flex-1 space-x-7 pr-7">
            <div className="w-6/12">
              <SearchBar
                onSearch={handleSearchTextChange}
                initialValue={query.q || ''}
              />
            </div>
            <div className="md:flex-1">
              <FilterCampaignList
                onSearch={handleFiltersChange}
                initialStatuses={query.filters ? query.filters.split(',') : []}
                initialCampaignTypes={query.campaignType ? query.campaignType.split(',') : []}
              />
            </div>
          </div>
          <div className="w-fit">
            <RadioBox
              onChange={(e: any) => {
                setDisplayType(e.target.value);
                updateQueryParams({ displayType: e.target.value });
              }}
              justIcons={true}
              boxSize="small"
              name="displayType"
              options={options}
              label=""
            />
          </div>
        </div>
      </ClassicCard>

      <div className="campaigns-section">
        {/* Drafts Section */}
        {drafts && drafts.length > 0 && (
          <div className="campaigns-category">
            <ClassicCard otherClasses="px-5 py-4 my-4">
              <h1 className="text-lg font-semibold dark:text-white text-dark">
                Drafts
              </h1>
            </ClassicCard>
            <div
              className={`campaigns-grid my-7 ${
                displayType === 'grid'
                  ? 'grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-6'
                  : 'w-full'
              }`}
            >
              {displayType === 'grid' ? (
                <DisplayGrid deleteCallback={deleteCallback} campaigns={drafts} />
              ) : (
                <DisplayList campaigns={drafts} />
              )}
            </div>
            <Pagination
              currentPage={pagination.pageDrafts}
              pageSize={pagination.pageSizeDrafts}
              totalItems={pagination.totalDrafts}
              onPageChange={(newPage: any) => handlePageChange('drafts', newPage)}
            />
          </div>
        )}

        {/* Own Campaigns Section */}
        {ownCampaigns && ownCampaigns.length > 0 && (
          <div className="campaigns-category my-7">
            <ClassicCard otherClasses="px-5 py-4 mb-7">
              <h1 className="text-lg font-semibold dark:text-white text-dark">
                Published Campaigns
              </h1>
            </ClassicCard>
            <div
              className={`campaigns-grid ${
                displayType === 'grid'
                  ? 'grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-6'
                  : 'w-full'
              }`}
            >
              {displayType === 'grid' ? (
                <DisplayGrid deleteCallback={deleteCallback} campaigns={ownCampaigns} />
              ) : (
                <DisplayList campaigns={ownCampaigns} />
              )}
            </div>
            <Pagination
              currentPage={pagination.pageOwn}
              pageSize={pagination.pageSizeOwn}
              totalItems={pagination.totalOwn}
              onPageChange={(newPage: any) => handlePageChange('own', newPage)}
            />
          </div>
        )}

        {/* Bulk Campaigns Section */}
        {bulkCampaigns && bulkCampaigns.length > 0 && (
          <div className="campaigns-category my-7">
            <ClassicCard otherClasses="px-5 py-4 mb-7">
              <h1 className="text-lg font-semibold dark:text-white text-dark">
                Bulk Campaigns
              </h1>
            </ClassicCard>
            <div
              className={`campaigns-grid ${
                displayType === 'grid'
                  ? 'grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-6'
                  : 'w-full'
              }`}
            >
              {displayType === 'grid' ? (
                <DisplayGrid deleteCallback={deleteCallback} campaigns={bulkCampaigns} />
              ) : (
                <DisplayList campaigns={bulkCampaigns} />
              )}
            </div>
            <Pagination
              currentPage={pagination.pageBulk}
              pageSize={pagination.pageSizeBulk}
              totalItems={pagination.totalBulk}
              onPageChange={(newPage: any) => handlePageChange('bulk', newPage)}
            />
          </div>
        )}

        {/* No campaigns message */}
        {(!drafts || drafts.length === 0) &&
          (!ownCampaigns || ownCampaigns.length === 0) &&
          (!bulkCampaigns || bulkCampaigns.length === 0) && (
            <div className="no-campaigns-message">
              <p>No campaigns found.</p>
            </div>
          )}
      </div>
    </>
  );
};

export default MyCampaigns;
