import UAParser from 'ua-parser-js';

export const fetchUserClientData = async (user: any) => {
  const parser = new UAParser();
  const result = parser.getResult();
  const browser = result.browser.name || 'Unknown';
  const os = result.os.name || 'Unknown';

  let ip = '';
  let fingerprintId = '';

  try {
    const ipResponse = await fetch('https://api.ipify.org?format=json');
    const ipData = await ipResponse.json();
    ip = ipData.ip;

    if (typeof window !== 'undefined') {
      const module: any = await import('clientjs');
      const ClientJS = module.ClientJS;
      const client = new ClientJS();
      fingerprintId = client.getFingerprint();
    }
  } catch (error) {
    console.error('Eroare la obținerea datelor utilizator:', error);
  }

  const userData = {
    ip,
    browser,
    os,
    fingerprintId,
    email: user?.email || '-',
  };

  return userData;
};
