import React, { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useIntl, FormattedMessage } from 'react-intl';

import { Header, Input, Button, SelectInput, RadioBox, Radio } from 'components/common/atoms';
import { Stack } from 'components/layout';
import { IUserCard, IUserInvoice, IUserOrganization } from 'interface';
import { TPSAPI } from 'api/users';
import { useApp } from 'components/app';

type IProps = {
  notifications: string;
};
const ProfileNotifications: React.FC<IProps> = ({ notifications }: IProps) => {
  const { notify } = useApp();

  const [optionsRadio, setOptionRadio] = React.useState([
    { value: 'never', label: 'Never', checked: false },
    { value: 'digest', label: 'Daily Digest', checked: false },
    { value: 'immediate', label: 'Immediate', checked: false },
  ]);
  useEffect(() => {
    let r = optionsRadio.map(el => {
      if (el.value === notifications) el.checked = true;
      else el.checked = false;
      return el;
    });
    setOptionRadio(r);
  }, []);
  return (
    <div className="flex items-center justify-center flex-1">
      <div className="w-fit space-y-7">
        <div className="px-2 text-base dark:text-dark-400 font-medium text-center md:text-sm md:text-left">Notification frequency</div>
        <div className="flex flex-col items-center justify-center md:flex-row space-y-7 md:space-y-0 md:space-x-4">
          {optionsRadio?.map((option, indexSch: number) => (
            <Radio
              justText={true}
              key="1"
              onChange={async (e: any) => {
                await TPSAPI.setUserNotifications(e.target.value);
                notify('Notifications settings updated', 'success');
              }}
              checked={option.checked}
              name="primary"
              defaultBg="bg-white"
              label={option.label}
              value={option.value}
              labelColor="dark"
            />
          ))}
        </div>

        {/* <RadioBox
          boxSize="big"
          key="1"
          onChange={async (e: any) => {
            await TPSAPI.setUserNotifications(e.target.value)
            notify('Notifications settings updated', 'success')
          }}
          name="primary"
          options={optionsRadio}
          labelSmall=""
        /> */}
        <div className="pt-4 text-lg font-medium dark:text-dark-300 text-center text-dark-default md:text-base">
          You will only receive one email per day with a summary of the day's events.{' '}
        </div>
      </div>
    </div>
  );
};
export default ProfileNotifications;
