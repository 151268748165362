import { oneLine } from 'common-tags';
import { ICampaignList } from 'interface/ICampaign';
import { Table, TableProps, StatusPill } from 'components/common/molecules';
import { Icon } from '@iconify/react-with-api';
import { Delete, Show, Paper } from 'react-iconly';
import { ItemList } from '../../molecules/DisplayList';
import React from 'react';
import { DateTime } from 'luxon';
import numeral from 'numeral';
type IProps = {
  campaigns: ICampaignList[];
};

const DisplayList: React.FC<IProps> = ({ campaigns }: IProps) => {
  const ViewItems = (cell: any) => {
    return (
      <div className="md:flex justify-between md:w-32 space-y-4 md:space-y-0 md:space-x-4 p-3 md:p-0">
        <div className="flex items-center">
          <a
            className="flex items-center justify-center px-1 rounded-lg md:bg-dark-500 md:bg-opacity-5 text-dark-default dark:text-dark-400"
            href={`${
              cell.row.values.status !== 'draft'
                ? `/campaign/${cell.row.values.uuid}`
                : `publish/basic/?cid=${cell.row.values.uuid}`
            }`}
          >
            <div className="w-8 h-8">
              <Show set="light" />
            </div>
            <div className="md:hidden whitespace-pre dark:text-dark-400"> View campaign</div>
          </a>
        </div>
        {(cell.row.values.status !== 'draft' && (
          <div className="cursor-pointer flex items-center">
            <a
              href={`/publish/basic?id=${cell.row.values.uuid}`}
              className="flex items-center justify-center px-1 rounded-lg md:bg-dark-500 md:bg-opacity-5 text-dark-default dark:text-dark-400"
            >
              <div className="w-8 h-8">
                <Paper set="light" />
              </div>
              <div className="md:hidden whitespace-pre dark:text-dark-400"> Copy campaign</div>
            </a>
          </div>
        )) || <></>}
      </div>
    );
  };
  const header = [
    {
      Header: 'UUID',
      accessor: 'uuid',
    },
    {
      Header: 'Campaign name',
      accessor: 'name',
    },
    {
      Header: 'Created',
      accessor: 'created',
      Cell: (cell: any) => {
        return <div>{DateTime.fromMillis(cell.row.values.created).toFormat('yyyy-MM-dd HH:mm')}</div>;
      },
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: StatusPill,
    },

    {
      Header: 'Plays',
      accessor: 'plays',
      Cell: (cell: any) => {
        return <div>{numeral(cell.row.values.plays).format('0,0')}</div>;
      },
    },
    {
      Header: 'Impressions',
      accessor: 'reach',
      Cell: (cell: any) => {
        return <div>{numeral(cell.row.values.reach).format('0,0')}</div>;
      },
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      Cell: ViewItems,
    },
  ];

  const initialState = { hiddenColumns: ['uuid'] };

  return (
    <div className="w-full">
      <Table header={header} tableData={campaigns} initialState={initialState} />
    </div>
  );
};

export default DisplayList;
