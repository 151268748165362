import React, { useCallback, useState, useEffect } from 'react';
import { Stack } from 'components/layout';
import { Button, Input, InputPhone, Header } from 'components/common/atoms';

import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import Link from 'next/link';
import { useApp } from 'components/app';
import { oneLine } from 'common-tags';
import { DateTime } from 'luxon';
import zxcvbn from 'zxcvbn';
import { TPSAPI } from 'api';
import AuthContainer from './AuthContainer';
type IProps = {
  pid: string;
};

const ChangePasswordForm: React.FC<IProps> = ({ pid }: IProps) => {
  const intl = useIntl();
  const { notify } = useApp();

  const [loading, setLoading] = useState<boolean>(false);
  const [expired, setExpired] = useState<boolean>(false);
  const [done, setDone] = useState<boolean>(false);
  const [emailRecover, setEmailRecover] = useState<boolean>(false);
  const [token, setToken] = useState<string>('');

  useEffect(() => {
    if (pid) {
      const { token: newToken, email, expires } = JSON.parse(atob(pid));
      if (DateTime.utc().toMillis() > expires) {
        setExpired(true);
      }
      setEmailRecover(email);
      setToken(newToken);
    }
    return () => {};
  }, []);

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(
        8,
        intl.formatMessage(
          {
            defaultMessage: 'The field needs to be at least {min} characters long',
            id: 'Error.minimumLength',
          },
          { min: '8' }
        )
      )
      .test(
        'test-name',
        intl.formatMessage({
          defaultMessage: "The password doesn't meet the minimum requirements",
          id: 'Error.passwordMinimunRequirements',
        }),
        value => {
          if (value) {
            return zxcvbn(value).score >= 2;
          } else {
            return false;
          }
        }
      )
      .required(
        intl.formatMessage({
          defaultMessage: 'Field is mandatory',
          id: 'Error.mandatoryField',
        })
      ),
  });

  const useYupValidationResolver = (schema: any) =>
    useCallback(
      async (data: any) => {
        try {
          const values = await schema.validate(data, {
            abortEarly: false,
          });

          return {
            values,
            errors: {},
          };
        } catch (errors: any) {
          return {
            values: {},
            errors: errors.inner?.reduce(
              (allErrors: any, currentError: any) => ({
                ...allErrors,
                [currentError.path]: {
                  type: currentError.type ?? 'validation',
                  message: currentError.message,
                },
              }),
              {}
            ),
          };
        }
      },
      [schema]
    );

  const resolver = useYupValidationResolver(validationSchema);
  const { register, handleSubmit,  formState } = useForm({ resolver });

  const onSubmit = async (data: any) => {
    setLoading(true);
    const response = await TPSAPI.passwordChangeToken(data.password, token);
    if (response.success === true) {
      setDone(true);
    } else {
      notify(response.errorMessage, 'warning');
    }
    setLoading(false);
  };
  const { dirtyFields, errors } = formState;
  const getValidation = (inputName: any) => {
    const val = {
      isDirty: (dirtyFields as any)[inputName],
      error: (errors as any)[inputName],
    };
    return val;
  };

  return (
    <AuthContainer isRegister={true}>
      <Stack direction="horizontal" gravity="center" wrap={true} otherClass="tablet:w-full min-tablet:pr-16">
        <div className="md:max-w-screen-sm tablet:w-full">
          <div className="w-full px-16 py-10 bg-white dark:bg-dark-200 border-0 rounded-4xl tablet:rounded-b-none">
            {done && (
              <div className="w-full bg-white dark:bg-dark-200 border rounded">
                <Stack direction="horizontal" spacing="xl" wrap={true} otherClass="pb-10 text-center">
                  <Header title="Change password" subtitle="You've successfully changed your password." />
                  <div className="flex justify-center mx-auto text-center w-72 mt-7">
                    <Link href="/auth/login">
                      <Button type="button" color="primary">
                        {intl.formatMessage({
                          defaultMessage: 'Login',
                          id: 'Button.Login',
                        })}
                      </Button>
                    </Link>
                  </div>
                </Stack>
              </div>
            )}
            {expired && !done && (
              <div className="w-full bg-white dark:bg-dark-200 border rounded">
                <Stack direction="horizontal" spacing="xl" wrap={true} otherClass="pb-10 text-center">
                  <Header title="Change password" subtitle="the link has expired. Please try again." />
                  <div className="flex justify-center mx-auto text-center w-72 mt-7">
                    <Link href="/auth/forgot-password">
                      <Button type="button" color="primary">
                        {intl.formatMessage({
                          defaultMessage: ' Try again',
                          id: 'Button.TryAgain',
                        })}
                      </Button>
                    </Link>
                  </div>
                </Stack>
              </div>
            )}
            {!expired && !done && (
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="w-full bg-white dark:bg-dark-200 rounded">
                  <Stack direction="horizontal" spacing="xl" wrap={true} otherClass="">
                    <Header title="Change password" subtitle={`Change password for ${emailRecover}`} />
                    <Stack fitX={true} padding={true} spacing="lg" direction="horizontal" wrap={true}>
                      <Input
                        name="password"
                        innerRef={register}
                        validation={getValidation('password')}
                        type="password"
                        label="Password"
                        className="input-s"
                        showStrength={true}
                        fullWidth={true}
                      />
                    </Stack>
                    <Stack fitX={true} wrap={true} gravity="center" otherClass="w-72 mx-auto mt-7">
                      <Button type="submit" color="primary" loading={loading} fullWidth>
                        {intl.formatMessage({
                          defaultMessage: 'Change password',
                          id: 'Button.ChangePassword',
                        })}
                      </Button>
                    </Stack>
                  </Stack>
                </div>
              </form>
            )}
          </div>
        </div>
      </Stack>
    </AuthContainer>
  );
};
export default ChangePasswordForm;
