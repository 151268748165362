import { TPSAPI } from '@api/users';
import { oneLine } from 'common-tags';
import { DateTime } from 'luxon';
import React, { ReactElement, useEffect } from 'react';
import PulseLoader from 'react-spinners/PulseLoader';
import { Time } from '../atoms/CampaignCard';

type NotificationsContainerProps = {
  closeAction: () => void;
};

const NotificationsContainer: React.FC<NotificationsContainerProps> = ({
  closeAction,
}: NotificationsContainerProps) => {
  const [notifications, setNotifications] = React.useState<any>(null);

  const getNotificati = async () => {
    const notificarile = await TPSAPI.getNotificationsAll();
    setNotifications(notificarile.notifications);
  };

  const getLabelTitleByValue = (input: string): string => {
    const words = input.split('-');

    const transformedWords = words.map(word => {
      const firstLetter = word.charAt(0).toUpperCase();
      const restOfWord = word.slice(1);
      return firstLetter + restOfWord;
    });

    const transformedString = transformedWords.join(' ');

    return transformedString;
  };
  useEffect(() => {
    getNotificati();
  }, []);

  return (
    <div
      className={
        'absolute z-0 max-lg:z-100 p-6 pr-2 bg-white dark:bg-dark-200 border dark:border-2 dark:border-dark-default h-80vh top-8 max-lg:right-0 max-lg:left-2 max-lg:top-30 rounded-2xl w-72 flex flex-col notifications-container-with'
      }
    >
      <div className="flex flex-row w-full justify-between items-center mb-4 relative">
        <h1 className="text-black dark:text-white break-words">Notifications</h1>
        <button
          onClick={closeAction}
          data-cy="popClose"
          className="absolute px-3 py-3 transition duration-300 bg-white rounded-full -right-7 -top-9 md:px-2 md:py-2 dark:border-2 border-2 dark:border-dark-default dark:bg-dark-200 z-99999  hover:rotate-180"
        >
          <svg
            className="dark:hidden"
            width="15"
            height="15"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.98959 9.01041L21.0104 21.0312M21.0104 9.01041L8.98959 21.0312"
              stroke="#858D96"
              strokeWidth="2.5"
              strokeLinecap="round"
            />
          </svg>
          <svg
            className="hidden dark:block"
            width="15"
            height="15"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.98959 9.01041L21.0104 21.0312M21.0104 9.01041L8.98959 21.0312"
              stroke="#E4DEDE"
              strokeWidth="2.5"
              strokeLinecap="round"
            />
          </svg>
        </button>
      </div>
      <div className="mt-2 overflow-auto pr-4">
        {notifications &&
          notifications.map((el: any, index: number) => {
            return (
              <div
                key={index}
                className={`flex flex-row ${
                  index !== notifications.length - 1
                    ? 'border-b dark:border-bordercolor dark:border-opacity-20 pb-4 mb-4'
                    : ''
                } `}
              >
                <div className="w-2/12">
                  <img src={`/noti-icons/${getLabelTitleByValue(el.type).replace(/\s/g, '')}.svg`} />
                </div>
                <div className="flex flex-col w-10/12 pl-2 md:pl-0">
                  <div className="flex flex-row items-center justify-between flex-wrap">
                    <h3 className="text-md text-dark-500 font-semibold dark:text-dark-400">{el.content.title}</h3>
                    <div className="flex flex-row items-center">
                      <p className="text-xs text-dark-500 dark:text-dark-300 text-right">
                        <Time label={false} time={DateTime.fromISO(el.createdAt).toMillis()} />
                      </p>
                      {el.viewed <= el.target_view + 1 && <div className="unread-notification ml-2"></div>}
                    </div>
                  </div>
                  <p className="text-sm dark:text-dark-400 text-left">{el.content.content}</p>
                </div>
              </div>
            );
          })}
        {notifications && notifications.length === 0 && (
          <div className={`flex flex-row border-b dark:border-bordercolor dark:border-opacity-20 pb-4 mb-4`}>
            <div className="w-2/12">
              <img src={`/noti-icons/GeneralSuccess.svg`} />
            </div>
            <div className="flex flex-col w-10/12 pl-2 md:pl-0">
              <div className="flex flex-row items-center justify-between flex-wrap">
                <h3 className="text-md text-dark-500 font-semibold dark:text-dark-400">No News is Good News</h3>
              </div>
              <p className="text-sm dark:text-dark-400 text-left">
                You don't have any notifications at this time. Check back later to see if there are any updates.{' '}
                <br></br> If you have any questions, please don't hesitate to contact us. Thank you for using our app!
              </p>
            </div>
          </div>
        )}
        {!notifications && (
          <div className={`flex flex-col dark:border-grey-default pb-2 mb-4`}>
            <div className="flex flex-row items-start mb-2">
              <div className="w-2/12">
                <div className="flex items-start justify-start w-12 h-12 dark:bg-dark-400 bg-dark-300 dark:bg-opacity-10 bg-opacity-30 rounded-full flex-shrink-0"></div>
              </div>
              <div className="w-10/12 flex flex-col items-end">
                <div className="flex flex-row w-full mb-3 justify-between">
                  <p className="text-xs text-transparent dark:bg-dark-400 bg-dark-300 dark:bg-opacity-10 bg-opacity-30 rounded-xl text-right w-8/12 float-right">
                    a
                  </p>
                  <h3 className="text-sm font-bold text-transparent dark:bg-dark-400 bg-dark-300 dark:bg-opacity-10 bg-opacity-30 rounded-xl w-2/12">
                    a
                  </h3>
                </div>
                <p className="text-sm text-transparent dark:bg-dark-400 bg-dark-300 dark:bg-opacity-10 bg-opacity-30 rounded-xl mb-2 w-full">
                  a
                </p>
                <p className="text-sm text-transparent dark:bg-dark-400 bg-dark-300 dark:bg-opacity-10 bg-opacity-30 rounded-xl mb-2 w-full">
                  a
                </p>
              </div>
            </div>
            <div className="flex flex-col items-center">
              <PulseLoader color={'#C1C5CA'} size={6} loading={true} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

NotificationsContainer.defaultProps = {};

export default NotificationsContainer;
