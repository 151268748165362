import React, { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';

import { Input, Button } from 'components/common/atoms';
import { Stack } from 'components/layout';
import { IUserOrganization } from 'interface';
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import { oneLine } from 'common-tags';
import { TPSAPI } from 'api';
import { useApp } from 'components/app';
import { AddressField, Dialog } from 'components/common/molecules';
import { ISugestion } from 'interface/ISugestion';
import { IGeoCountries } from 'interface/ILocation';
import { useRouter } from 'next/router';

type ProfileProps = {
  company: IUserOrganization;
  countries: IGeoCountries[];
};
interface IFormValues {
  firstName: string;
  lastName: string;
  businessName: string;
  regNumber: string;
  country: string;
  city: string;
  companyType: number;
  vatSwitch: number;
}

const ProfileOrganization: React.FC<ProfileProps> = ({ company, countries }: ProfileProps) => {
  const intl = useIntl();
  const { notify, setUser } = useApp();
  const [userData, setUserData] = React.useState<IUserOrganization>(company);
  const [geoCountries, setGeoCountris] = React.useState<IGeoCountries[]>(countries);
  const [selectedAccountType, setSelectedAccountType] = useState<number | null>(
    userData.companyType ? (userData.companyType === 'person' ? 1 : 2) : null
  );
  const [selectedCountry, setSelectedCountry] = useState<string>(userData.countryIso3 ? userData.countryIso3 : '');
  const [locationId, setLocationId] = useState<string>(userData.locationId ? userData.locationId : '');
  const [latitude, setLatitude] = useState<string | undefined>('');
  const [longitude, setLongitude] = useState<string | undefined>('');

  const [vatSwitch, setVatSwitch] = useState<number | null>(userData.vat ? (userData.vat === 'true' ? 1 : 2) : 1);
  const [loading, setLoading] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);

  const router = useRouter();
  const [acountTypes] = useState<any[]>([
    {
      id: 1,
      title: 'Personal Account',
      description: 'Same benefits as a business account, but less hassle! Invoices will be issued to you directly. ',
      icon: 'personal-account',
    },
    {
      id: 2,
      title: 'Business Account',
      description:
        'Perfect if you need the invoices to reflect your company information (also makes you look cooler). ',
      icon: 'company-account',
    },
  ]);
  const [vatTypes, setVatTypes] = useState<any[]>([
    {
      id: 1,
      title: 'Yes, we pay VAT',
    },
    {
      id: 2,
      title: "No, we don't pay VAT",
    },
  ]);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().when('companyType', {
      is: '1',
      then: Yup.string()
        .min(
          2,
          intl.formatMessage(
            {
              defaultMessage: 'The field needs to be at least {min} characters long',
              id: 'Error.minimumLength',
            },
            { min: '2' }
          )
        )
        .required(
          intl.formatMessage({
            defaultMessage: 'Field is mandatory',
            id: 'Error.mandatoryField',
          })
        ),
    }),
    lastName: Yup.string().when('companyType', {
      is: '1',
      then: Yup.string()
        .min(
          2,
          intl.formatMessage(
            {
              defaultMessage: 'The field needs to be at least {min} characters long',
              id: 'Error.minimumLength',
            },
            { min: '2' }
          )
        )
        .required(
          intl.formatMessage({
            defaultMessage: 'Field is mandatory',
            id: 'Error.mandatoryField',
          })
        ),
    }),
    country: Yup.string()
      .min(
        3,
        intl.formatMessage(
          {
            defaultMessage: 'The field needs to be at least {min} characters long',
            id: 'Error.minimumLength',
          },
          { min: '3' }
        )
      )
      .required(
        intl.formatMessage({
          defaultMessage: 'Field is mandatory',
          id: 'Error.mandatoryField',
        })
      ),
    city: Yup.string()
      .min(
        3,
        intl.formatMessage(
          {
            defaultMessage: 'The field needs to be at least {min} characters long',
            id: 'Error.minimumLength',
          },
          { min: '3' }
        )
      )
      .required(
        intl.formatMessage({
          defaultMessage: 'Field is mandatory',
          id: 'Error.mandatoryField',
        })
      ),
    address: Yup.string()
      .min(
        3,
        intl.formatMessage(
          {
            defaultMessage: 'The field needs to be at least {min} characters long',
            id: 'Error.minimumLength',
          },
          { min: '3' }
        )
      )
      .required(
        intl.formatMessage({
          defaultMessage: 'Field is mandatory',
          id: 'Error.mandatoryField',
        })
      ),
    businessName: Yup.string().when('companyType', {
      is: '2',
      then: Yup.string()
        .min(
          3,
          intl.formatMessage(
            {
              defaultMessage: 'The field needs to be at least {min} characters long',
              id: 'Error.minimumLength',
            },
            { min: '3' }
          )
        )
        .required(
          intl.formatMessage({
            defaultMessage: 'Field is mandatory',
            id: 'Error.mandatoryField',
          })
        ),
    }),
    regNumber: Yup.string().when('vatSwitch', {
      is: '1',
      then: Yup.string()
        .min(
          4,
          intl.formatMessage(
            {
              defaultMessage: 'The field needs to be at least {min} characters long',
              id: 'Error.minimumLength',
            },
            { min: '4' }
          )
        )
        .required(
          intl.formatMessage({
            defaultMessage: 'Field is mandatory',
            id: 'Error.mandatoryField',
          })
        ),
    }),
  });

  const useYupValidationResolver = (schema: any) =>
    useCallback(
      async (data: any) => {
        try {
          const values = await schema.validate(data, {
            abortEarly: false,
          });
          return {
            values,
            errors: {},
          };
        } catch (errors: any) {
          return {
            values: {},
            errors: errors.inner.reduce(
              (allErrors: any, currentError: any) => ({
                ...allErrors,
                [currentError.path]: {
                  type: currentError.type ?? 'validation',
                  message: currentError.message,
                },
              }),
              {}
            ),
          };
        }
      },
      [schema]
    );

  const refreshData = () => {
    router.replace(router.asPath).catch(() => console.log('error'));
  };

  const resolver = useYupValidationResolver(validationSchema);
  const { register, handleSubmit, formState, control, setValue, getValues } = useForm<IFormValues>({
    resolver,
    defaultValues: {
      firstName: userData.firstName,
      lastName: userData.lastName,
      city: userData.city,
      country: userData.country,
      businessName: userData.name,
      vatSwitch: userData.vat === 'true' ? 1 : 2,
      companyType: selectedAccountType || undefined,
    },
  });
  console.log('selectedAccountType', selectedAccountType);

  const onSubmit = async (data: any) => {
    try {
      const payload = {
        companyId: data.regNumber ? data.regNumber : '-',
        additionalId: data.additionalId ? data.additionalId : '-',
        name: data.companyType === '1' ? data.firstName + ' ' + data.lastName : data.businessName,
        firstName: data.companyType === '1' ? data.firstName : '-',
        lastName: data.companyType === '1' ? data.lastName : '-',
        vat: vatSwitch === 1 ? 'true' : 'false',
        companyType: data.companyType === '1' ? 'person' : 'company',
        address: {
          country: data.country,
          city: data.city,
          address: data.address,
          iso3: selectedCountry,
          locationId: locationId ? locationId : '0',
          latitude: latitude ? latitude : '-',
          longitude: longitude ? longitude : '-',
        },
      };
      setLoading(true);
      const response = await TPSAPI.setUserCompanyData(payload);
      console.log('response', response);
      if (response.hasError) {
        notify(response.message || '', 'error');
      } else {
        notify(response.message || '', 'success');
        refreshData();
      }
    } catch (error) {
      notify('Try again later', 'success');
    }
  };

  const { dirtyFields, errors } = formState;
  const getValidation = (inputName: any) => {
    const val = {
      isDirty: (dirtyFields as any)[inputName],
      error: (errors as any)[inputName],
    };
    return val;
  };

  useEffect(() => {
    if (selectedAccountType === 1) {
      setValue('vatSwitch', 1);
    } else {
      setValue('vatSwitch', 0);
    }
  }, [selectedAccountType]);

  useEffect(() => {
    setUserData(company);
  }, [company]);

  useEffect(() => {
    if (selectedCountry !== userData.countryIso3) {
      userData.city = '';
      setValue('city', '');
    }
  }, [selectedCountry]);

  useEffect(() => {
    setLoading(false);
  }, [userData]);

  const primaryButton = {
    buttonText: 'Ok',
    action: () => {
      setDialogOpen(false);
    },
  };

  const secondaryButton = {
    buttonText: 'Cancel',
    action: () => {
      setDialogOpen(false);
    },
  };

  console.log('getValues().companyType ', getValues());
  return (
    <div className="flex justify-center flex-1 w-full flex-column">
      {(dialogOpen && (
        <Dialog
          description={
            'Additional verifications are needed for this action \n. If you want to switch to a personal account, please contact the support team via the Chat button from the bottom-right corner of the screen.'
          }
          title={'Switch to Personal Account'}
          primaryButton={primaryButton}
          secondaryButton={secondaryButton}
        />
      )) ??
        null}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={oneLine`max-w-full ${!selectedAccountType ? 'md:max-w-2xl' : ''} mx-auto`}>
          <div className="z-10 flex-wrap justify-start space-y-4 md:flex ">
            <div className="w-full text-center">
              <h1 className="my-8 text-lg font-semibold dark:text-white text-dark">
                {!selectedAccountType
                  ? 'Choose what type of account do you want:'
                  : `Insert details of your ${acountTypes[selectedAccountType - 1].title}`}
              </h1>
            </div>

            <div className="hidden">
              <Input
                className="input-s"
                name="companyType"
                type="hidden"
                innerRef={register}
                key={selectedAccountType ? selectedAccountType.toString() : '2'}
                value={selectedAccountType ? selectedAccountType.toString() : '2'}
                validation={getValidation('companyType')}
                placeholder="Insert your email"
                label=""
                fullWidth={true}
              />
              <Input
                className="input-s"
                name="vatSwitch"
                type="hidden"
                innerRef={register}
                validation={getValidation('vatSwitch')}
                placeholder="Insert your email"
                label=""
                fullWidth={true}
              />
            </div>
            <div className="flex flex-row items-center justify-around w-full space-x-7">
              {!selectedAccountType &&
                acountTypes.map((accountType: any) => {
                  return (
                    <div
                      key={accountType.id}
                      className="flex flex-col items-center justify-center px-3 py-5 bg-white dark:bg-dark-200 rounded-3xl"
                    >
                      <div className="mb-4">
                        <img
                          src={`/images/${accountType.icon}-dark.svg`}
                          loading="lazy"
                          className="block m-auto dark:hidden"
                        />
                        <img
                          src={`/images/${accountType.icon}.svg`}
                          loading="lazy"
                          className="hidden m-auto dark:block"
                        />
                      </div>
                      <h1 className="font-semibold dark:text-dark-400">{accountType.title}</h1>
                      <h1 className="mt-2 mb-4 text-center dark:text-dark-300">{accountType.description}</h1>

                      <Button
                        onClick={() => {
                          setSelectedAccountType(accountType.id);
                          setValue('companyType', accountType.id);
                        }}
                        className="mt-3"
                        fullWidth={true}
                        type="button"
                        fill="outlinedark"
                        color="primary"
                      >
                        Select
                      </Button>
                    </div>
                  );
                })}
            </div>
            {selectedAccountType && (
              <>
                <Stack fitX={true} spacing="xl" padding={true}>
                  {selectedAccountType === 1 ? (
                    <>
                      <Input
                        className="input-s"
                        name="firstName"
                        innerRef={register}
                        validation={getValidation('firstName')}
                        type="text"
                        label={intl.formatMessage({
                          defaultMessage: 'First Name',
                          id: 'Label.Firstname',
                        })}
                        defaultValue={userData.firstName}
                        fullWidth={true}
                      />
                      <Input
                        className="input-s"
                        name="lastName"
                        innerRef={register}
                        validation={getValidation('lastName')}
                        type="text"
                        label={intl.formatMessage({
                          defaultMessage: 'Last Name',
                          id: 'Label.Lastname',
                        })}
                        defaultValue={userData.lastName}
                        fullWidth={true}
                      />
                    </>
                  ) : (
                    <div className="w-full">
                      <Stack fitX={true} spacing="xl" padding={false}>
                        <Input
                          className="input-s"
                          name="businessName"
                          innerRef={register}
                          validation={getValidation('businessName')}
                          type="text"
                          label={intl.formatMessage({
                            defaultMessage: 'Business name*',
                            id: 'Label.BusinessName',
                          })}
                          defaultValue={userData.name}
                          fullWidth={true}
                          hint={"Switch to a personal account if you don't have a company."}
                        />
                        <Controller
                          control={control}
                          name="country"
                          render={({ onChange, onBlur, value, name, ref }, { invalid, isTouched, isDirty }) => (
                            <AddressField
                              placeholderText={intl.formatMessage({
                                defaultMessage: 'Write a country here',
                                id: 'label.Writeacountry',
                              })}
                              innerRef={register}
                              selName="country"
                              api={null}
                              validation={getValidation('country')}
                              source="here-auth"
                              sourceData={geoCountries}
                              hasDefaultValue={true}
                              defaultValue={
                                userData.country && {
                                  label: userData.country ? userData.country : null,
                                  id: userData.country,
                                  value: userData.country,
                                }
                              }
                              onChange={(e: any) => {
                                if (e && e.value && e.country) {
                                  setSelectedCountry(e.country);
                                  onChange(e.label);
                                } else {
                                  onChange('');
                                }
                              }}
                              fullWidth={true}
                              label="Billing country or region*"
                              hint="The legal country or region registered with your government and tax agency."
                            />
                          )}
                        />
                        <Controller
                          control={control}
                          name="city"
                          render={({ onChange, onBlur, value, name, ref }, { invalid, isTouched, isDirty }) => (
                            <AddressField
                              key={`${userData.city}_${selectedCountry}`}
                              placeholderText={userData.city}
                              innerRef={register}
                              selName="city"
                              api={null}
                              validation={getValidation('city')}
                              source="here"
                              hasDefaultValue={true}
                              defaultValue={
                                userData.city && {
                                  label: userData.city,
                                  id: userData.city,
                                  value: userData.city,
                                }
                              }
                              onChange={(data: ISugestion) => {
                                if (data) {
                                  setLocationId(data.locationId);
                                  setLatitude(data.latitude);
                                  setLongitude(data.longitude);
                                  onChange(data.value);
                                } else {
                                  onChange('');
                                }
                              }}
                              fullWidth={true}
                              label="City"
                              resultType="city"
                              countryArea={selectedCountry}
                              suggestionsType="city"
                              disabled={selectedCountry ? false : true}
                            />
                          )}
                        />
                      </Stack>
                      <Stack fitX={true} spacing="xl" padding={false}>
                        <Input
                          className="input-s"
                          name="address"
                          innerRef={register}
                          validation={getValidation('address')}
                          type="text"
                          label={intl.formatMessage({
                            defaultMessage: 'Address',
                            id: 'Label.Address',
                          })}
                          defaultValue={userData.address}
                          fullWidth={true}
                        />
                      </Stack>
                    </div>
                  )}
                </Stack>
                {/* <div className='px-7'>
                    <h1 className='font-semibold dark:text-dark-400'>Billing Info</h1>
                    <h1 className='mt-2 mb-4 text-center dark:text-dark-300'>
                      Blindspot is required to share your business information with local tax authorities that request it.
                    </h1>
                </div> */}
                {selectedAccountType === 2 ? (
                  <Stack fitX={true} spacing="xl" padding={true}>
                    <div className="relative z-50 ml-4">
                      <label className="text-sm pointer-events-none animated origin-0 top-2 left-4 select-box-label dark:text-dark-400">
                        VAT
                      </label>
                      <div className="flex flex-row mt-2 flex-nowrap">
                        {vatTypes.map((vatType: any) => {
                          return (
                            <div
                              key={vatType.id}
                              className="flex flex-row dark:bg-dark-200 bg-[#D9D9D9] dark:text-white text-dark-200 rounded-2xl whitespace-nowrap p-3 mr-3 cursor-pointer"
                              onClick={() => {
                                setVatSwitch(vatType.id);
                                setValue('vatSwitch', vatType.id);
                              }}
                            >
                              <div
                                className={`w-5 h-5 border ${
                                  vatSwitch === vatType.id ? 'border-[#EB3F3F]' : 'border-dark-300'
                                } bg-white rounded-2xl flex items-center justify-center mr-4`}
                              >
                                <div
                                  className={`w-2 h-2 ${
                                    vatSwitch === vatType.id ? 'bg-[#EB3F3F]' : 'bg-white'
                                  } rounded-2xl`}
                                ></div>
                              </div>
                              {vatType.title}
                            </div>
                          );
                        })}
                      </div>
                      <div className={`text-dark-default dark:text-dark-300 w-fit text-xs pt-2 space-x-1`}>
                        <p className="inline">
                          If you're an European company and do not pay VAT, select NO. Otherwise, select YES.
                        </p>
                      </div>
                    </div>
                    {/* {vatSwitch === 1 && ( */}
                    <Input
                      name="regNumber"
                      className="input-s"
                      innerRef={register}
                      validation={getValidation('regNumber')}
                      type="text"
                      label={vatSwitch === 1 ? 'Tax Identification Number*' : 'Company Identification Number*'}
                      defaultValue={userData.regNumber}
                      fullWidth={false}
                      hint={
                        vatSwitch === 1
                          ? 'If your company is from the US, you should add the EIN here, if from outside US, the EU VAT Number or any other TAX ID for the invoice'
                          : 'Add your Company Identification Number to help us identify your company'
                      }
                    />
                    {/* )} */}
                    <Input
                      className="input-s"
                      name="additionalId"
                      innerRef={register}
                      validation={getValidation('additionalId')}
                      type="text"
                      label={intl.formatMessage({
                        defaultMessage: 'Additional Identification',
                        id: 'Label.AdditionalIdentification',
                      })}
                      defaultValue={userData.additionalId}
                      fullWidth={false}
                      hint={'Add any other information you consider necessary for the invoice'}
                    />
                  </Stack>
                ) : (
                  <>
                    <Stack fitX={true} spacing="xl" padding={true}>
                      <Input
                        name="regNumber"
                        innerRef={register}
                        validation={getValidation('regNumber')}
                        type="text"
                        className="input-s"
                        label={intl.formatMessage({
                          defaultMessage: 'Personal Identification Number',
                          id: 'Label.PersonalIdentificationNumber',
                        })}
                        defaultValue={userData.regNumber}
                        fullWidth={true}
                      />
                      <Controller
                        control={control}
                        name="country"
                        render={({ onChange, onBlur, value, name, ref }, { invalid, isTouched, isDirty }) => (
                          <AddressField
                            placeholderText={intl.formatMessage({
                              defaultMessage: 'Write a country here',
                              id: 'label.Writeacountry',
                            })}
                            innerRef={register}
                            selName="country"
                            api={null}
                            hasDefaultValue={true}
                            defaultValue={
                              userData.country && {
                                label: userData.country,
                                id: userData.country,
                                value: userData.country,
                              }
                            }
                            label="Country"
                            validation={getValidation('country')}
                            source="here-auth"
                            sourceData={geoCountries}
                            onChange={(e: any) => {
                              if (e && e.country && e.label) {
                                setSelectedCountry(e.country);
                                onChange(e.label);
                              } else {
                                onChange('');
                              }
                            }}
                            fullWidth={true}
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        name="city"
                        render={({ onChange, onBlur, value, name, ref }, { invalid, isTouched, isDirty }) => (
                          <AddressField
                            key={`${userData.city}`}
                            placeholderText={userData.city}
                            innerRef={register}
                            selName="city"
                            api={null}
                            validation={getValidation('city')}
                            source="here"
                            hasDefaultValue={true}
                            defaultValue={
                              userData.city && {
                                label: userData.city,
                                id: userData.city,
                                value: userData.city,
                              }
                            }
                            onChange={(data: ISugestion) => {
                              if (data) {
                                setLocationId(data.locationId);
                                setLatitude(data.latitude);
                                setLongitude(data.longitude);
                                onChange(data.value);
                              } else {
                                onChange('');
                              }
                            }}
                            fullWidth={true}
                            label="City"
                            resultType="city"
                            countryArea={selectedCountry}
                            suggestionsType="city"
                            disabled={selectedCountry ? false : true}
                          />
                        )}
                      />
                    </Stack>
                    <Stack fitX={true} spacing="xl" padding={true}>
                      <Input
                        className="input-s"
                        name="address"
                        innerRef={register}
                        validation={getValidation('address')}
                        type="text"
                        label={intl.formatMessage({
                          defaultMessage: 'Address',
                          id: 'Label.Address',
                        })}
                        defaultValue={userData.address}
                        fullWidth={true}
                      />
                    </Stack>
                  </>
                )}
                <div className="w-full mx-auto max-w-15">
                  <Button type="submit" color="secondary" fill="outline" loading={loading} fullWidth={true}>
                    {intl.formatMessage({
                      defaultMessage: 'Save Details',
                      id: 'Button.Save',
                    })}
                  </Button>
                  {!loading ? (
                    <h1
                      className="font-semibold text-center underline cursor-pointer mt-7 text-primary-500"
                      onClick={() => {
                        if (selectedAccountType - 1 === 1 && userData.companyType === 'company') {
                          setDialogOpen(true);
                        } else {
                          setSelectedAccountType(selectedAccountType - 1 === 0 ? 2 : 1);
                          setValue('companyType', selectedAccountType - 1 === 0 ? 2 : 1);
                        }
                      }}
                    >
                      Switch to a {selectedAccountType - 1 === 1 ? 'Personal Account' : 'Business Account'}
                    </h1>
                  ) : (
                    <h1 className="font-semibold text-center underline cursor-pointer mt-7 text-primary-500">
                      Loading...
                    </h1>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};
export default ProfileOrganization;
