import React, { useCallback, useState } from 'react';
import * as Yup from 'yup';
import { useIntl, FormattedMessage } from 'react-intl';

import { Header, Input, Button } from 'components/common/atoms';
import { Stack } from 'components/layout';
import { ILoginRequest, ILoginResponse, IMyUserInfo } from 'interface';
import Link from 'next/link';
import { Controller, useForm } from 'react-hook-form';
import { oneLine } from 'common-tags';
import { JWT, TPSAPI } from 'api';
import { useApp } from 'components/app';
import dynamic from 'next/dynamic';
import Avatar from '../Avatar';
import Appearance from 'components/common/atoms/Appearance';
const ReactCodeInput = dynamic(import('react-code-input'));
import jwt_decode from 'jwt-decode';
import OnboardingSwitch from 'components/common/atoms/OnboardingSwitch';

type ProfileProps = {
  user: IMyUserInfo;
};

const ProfileBasic: React.FC<ProfileProps> = ({ user }: ProfileProps) => {
  const intl = useIntl();
  const { notify, setUser, setReloadJwt, reloadJwt } = useApp();

  const validationSchema = Yup.object().shape({
    lastName: Yup.string()
      .min(
        2,
        intl.formatMessage(
          {
            defaultMessage: 'The field needs to be at least {min} characters long',
            id: 'Error.minimumLength',
          },
          { min: '2' }
        )
      )
      .required(
        intl.formatMessage({
          defaultMessage: 'Field is mandatory',
          id: 'Error.mandatoryField',
        })
      ),

    email: Yup.string()
      .email(
        intl.formatMessage({
          defaultMessage: 'This is not a valid email address',
          id: 'Error.emailInvalid',
        })
      )
      .required(
        intl.formatMessage(
          {
            defaultMessage: 'Field is mandatory',
            id: 'Error.minimumLength',
          },
          { min: '2' }
        )
      ),
    firstName: Yup.string()
      .min(
        2,
        intl.formatMessage(
          {
            defaultMessage: 'Field is mandatory',
            id: 'Error.minimumLength',
          },
          { min: '2' }
        )
      )
      .required(
        intl.formatMessage({
          defaultMessage: 'Field is mandatory',
          id: 'Error.mandatoryField',
        })
      ),
  });

  const useYupValidationResolver = (schema: any) =>
    useCallback(
      async (data: any) => {
        try {
          const values = await schema.validate(data, {
            abortEarly: false,
          });

          return {
            values,
            errors: {},
          };
        } catch (errors: any) {
          return {
            values: {},
            errors: errors.inner.reduce(
              (allErrors: any, currentError: any) => ({
                ...allErrors,
                [currentError.path]: {
                  type: currentError.type ?? 'validation',
                  message: currentError.message,
                },
              }),
              {}
            ),
          };
        }
      },
      [schema]
    );
  const [useData, setUserData] = React.useState<IMyUserInfo>(user);

  const jsonAvatar = user.avatar || '{}';
  const [loading, setLoading] = useState<boolean>(false);
  const [showValidationField, setShowValidationField] = useState<boolean>(
    user.newEmail && user.newEmail !== user.email ? true : false
  );
  const [validationFieldValue, setValidationFieldValue] = useState<string>('');
  const resolver = useYupValidationResolver(validationSchema);
  const { register, handleSubmit,  formState, control } = useForm({ resolver });

  const validateCode = async () => {
    if (validationFieldValue.length === 6) {
      const response = await TPSAPI.changeEmail(validationFieldValue);
      if (response.hasError) {
        notify(response.errorMessage, 'error');
      } else {
        notify(response.errorMessage, 'success');
        const jwtDecode: any = jwt_decode(response.accessToken);
        JWT.setJwt(response);
        const data = useData;
        data.email = jwtDecode.email;
        data.newEmail = undefined;
        setShowValidationField(false);
        setUserData(data);
        setReloadJwt(reloadJwt + 1);
      }
    }
  };
  const onSubmit = async (data: any) => {
    setLoading(true);
    const response = await TPSAPI.updateAccount({
      firstName: data.firstName,
      lastName: data.lastName,
      phone: data.phone,
      email: data.email,
    });

    setLoading(false);
    if (response.hasError === true) {
      notify(response.message  || "", 'error');
    } else {
      notify(response.message || "", 'success');
      if (response.token) {
        JWT.setJwt(response.token as unknown as ILoginResponse);
        setReloadJwt(reloadJwt + 1);
      }

      if (response.redirect === true) {
        setShowValidationField(true);
      }
    }
  };
  const { dirtyFields,errors } = formState;
  const getValidation = (inputName: any) => {
    const val = {
      isDirty: (dirtyFields as any)[inputName],
      error: (errors as any)[inputName],
    };
    return val;
  };
  return (
    <div className="flex flex-col justify-evenly">
      <div className="pb-8">
        <Avatar edit={true} starter={jsonAvatar} />
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={oneLine`max-w-full md:max-w-2xl mx-auto`}>
          <div className="z-10 flex-wrap justify-start space-y-8 md:flex ">
            <Stack fitX={true} spacing="xl" padding={true}>
              <Input
                name="firstName"
                innerRef={register}
                validation={getValidation('firstName')}
                type="text"
                label="First Name"
                defaultValue={useData.firstName}
                fullWidth={true}
              />
              <Input
                name="lastName"
                innerRef={register}
                validation={getValidation('lastName')}
                type="text"
                label="Last Name"
                defaultValue={useData.lastName}
                fullWidth={true}
              />
            </Stack>
            <Stack fitX={true} padding={true} spacing="xl">
              <Input
                name="email"
                innerRef={register}
                validation={getValidation('email')}
                type="text"
                label="Email"
                className="input-s"
                defaultValue={useData.email}
                fullWidth={true}
              />
              <Input
                name="phone"
                innerRef={register}
                validation={getValidation('phone')}
                type="text"
                className="input-s"
                label="Phone"
                defaultValue={useData.phone}
                fullWidth={true}
              />
            </Stack>
            {showValidationField === true && (
              <div className="w-full px-4 py-2 space-y-4 bg-white rounded-xl dark:bg-dark-200">
                <h3 className="text-xl font-medium text-center dark:text-dark-400">
                  {intl.formatMessage({
                    defaultMessage: `We've sent a code to your old email`,
                    id: 'ActivateEmail.WeveSentACode"',
                  })}{' '}
                </h3>
                <div className="flex justify-between">
                  <div className="w-9/12">
                    <ReactCodeInput
                      onChange={e => {
                        setValidationFieldValue(e);
                      }}
                      name="code"
                      inputMode="numeric"
                      type="number"
                      fields={6}
                    />
                  </div>
                  <div className="w-3/12">
                    <Button
                      onClick={validateCode}
                      type="reset"
                      color="accent"
                      fill="outline"
                      loading={loading}
                      fullWidth={true}
                    >
                      {intl.formatMessage({
                        defaultMessage: 'Validate',
                        id: 'Button.Validate',
                      })}
                    </Button>
                  </div>
                </div>
              </div>
            )}
            <div className="w-full mx-auto max-w-15">
              <Button type="submit" color="tertiary" loading={loading} fullWidth={true}>
                {intl.formatMessage({
                  defaultMessage: 'Save',
                  id: 'Button.Save',
                })}
              </Button>
            </div>
          </div>
          <div className="pt-8 onboarding-switch-mobile">
            <OnboardingSwitch></OnboardingSwitch>
          </div>
          <div className="pt-8">
            <Appearance></Appearance>
          </div>
        </div>
      </form>
    </div>
  );
};
export default ProfileBasic;
