import React, { useEffect } from 'react';
import { IUserInvoice } from 'interface';

import { TPSAPI } from 'api';
import { Table } from 'components/common/molecules';

import { DateTime } from 'luxon';
import { Download } from 'react-iconly';
import download from 'downloadjs';
type ProfileProps = {
  invoices: IUserInvoice[];
};

const columns = [
  {
    name: 'Number',
    selector: 'number',
    sortable: true,
  },
  {
    name: 'Campaigns',
    selector: 'campaigns',
    sortable: true,
  },
  {
    name: 'Amount',
    selector: 'amount',
    sortable: true,
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: true,
  },
  {
    name: 'Download',
    selector: 'download',
    sortable: true,
  },
];
type IPropsDownload = {
  uuid: string;
  number: string;
};
const DownloadIcon = ({ value }: any) => {
  const getInvoice = async () => {
    const response = await TPSAPI.getInvoice(value.uuid);
    download(response, `TPSENGAGE_${value.number}-${DateTime.now().toFormat('yyyy MM dd')}.pdf`, 'application/pdf');
  };
  return (
    <div className="flex justify-between w-32 space-x-4">
      <div className="flex items-center justify-center w-8 h-8 px-2 rounded-lg bg-dark-500 bg-opacity-5 ">
        <button
          onClick={() => {
            getInvoice();
          }}
        >
          <Download set="light" />
        </button>
      </div>
    </div>
  );
};
const ProfileInvoice: React.FC<ProfileProps> = ({ invoices }: ProfileProps) => {
  const [data, setData] = React.useState<any>([]);

  useEffect(() => {
    let invoiceData = [];
    for (const item of invoices) {
      invoiceData.push({
        // campaigns: item.campaigns.map((el) => el.name).join(', '),
        invoice: item.number,
        amount: `${item.amount} ${item.currency}`,
        status: item.status,
        download: item,
      });
    }
    setData(invoiceData);
  }, []);
  return (
    <div className="w-10/12 mx-auto  flex flex-column items-center justify-center flex-1">
      <Table
        header={[
          {
            Header: 'Invoice',
            accessor: 'invoice',
          },
          // {
          //   Header: 'Date',
          //   accessor: 'date'
          // },
          {
            Header: 'Status',
            accessor: 'status',
          },
          {
            Header: 'Total sum',
            accessor: 'amount',
          },
          {
            Header: 'Actions',
            accessor: 'download',
            Cell: DownloadIcon,
          },
        ]}
        tableData={data}
      />

      {/* <DataTable noHeader={true} pagination={true} paginationPerPage={15} columns={columns} data={data} /> */}
    </div>
  );
};
export default ProfileInvoice;
