import { oneLine } from 'common-tags';
import { Portal } from 'components/common/atoms/Portal';
import { ILocationContentType } from 'interface/ILocation';
import { useEffect, useState, useContext } from 'react';
import { TPSAPI } from '@api/users';
import { IMediaUpload } from 'interface/IMedia';
import { Button, Checkbox } from 'components/common/atoms';
import { useIntl } from 'react-intl';
import SearchBar from '../Search';
import { v4 as uuidv4 } from 'uuid';

import { PublishContext } from 'context/publish/publish.provider';
import { IPublishType } from 'context/publish/publish.reducer';
import PulseLoader from 'react-spinners/PulseLoader';
const baseUrl = 'https://storage.googleapis.com/engage-uploaded-videos/';

interface MediaDialogLibraryLibraryProps {
  resolution: [number, number];
  formatId: string;
  duration: number;
  uploadedmedia: IMediaUpload[];
  onClose: () => void;
  contentType: ILocationContentType;
}
export const MediaDialogLibraryLibrary = ({
  resolution,
  formatId,
  duration,
  uploadedmedia,
  contentType,
  onClose,
}: MediaDialogLibraryLibraryProps) => {
  const [filterMedia, setFilterMedia] = useState<IMediaUpload[]>([]);
  const [media, setMedia] = useState<IMediaUpload[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchText, setSearchText] = useState<string>('');
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const intl = useIntl();
  const { publishState, dispatch } = useContext(PublishContext);

  const getApiData = async () => {
    try {
      const data = await TPSAPI.getUserFormatMediaLibrary({
        resolution,
        duration,
        contentType,
      });
      const alreadyUploaded = uploadedmedia.map(m => m.oldUid || m.uid);

      setMedia(data.filter(m => !alreadyUploaded.includes(m.uid)));
      setFilterMedia(data.filter(m => !alreadyUploaded.includes(m.uid)));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (searchText.length > 0) {
      const filteredMedia = media.filter(m => {
        const name = m.oldName.toLowerCase();
        const search = searchText.toLowerCase();
        return name.includes(search);
      });
      setFilterMedia(filteredMedia);
    } else {
      setFilterMedia(media);
    }
  }, [searchText]);

  useEffect(() => {
    getApiData().catch(err => {
      console.log(err);
    });
  }, []);
  
  const addVisual = () => {
    console.log(selectedItems);
    const selectedMedia = media.filter(m => selectedItems.includes(m.uid));

    dispatch({
      type: IPublishType.adMultipledMediaToFormat,
      payload: {
        files: selectedMedia.map(m => {
          if (m.filename) {
            const fileType = m.filename.split('.').pop();
            const newUid = uuidv4();

            return {
              ...m,
              duration,
              type: 'uploaded',
              oldUid: m.uid,
              uid: newUid,
              filename: m.oldFilename,
              originalFilename: m.oldFilename,
              oldFilename: m.filename,
              file: {
                ...m.file,
                type: 'remote',
              },
              oldType: m.type,
              location: `${m.filename}`,
              action: 'isuploaded',
            };
          }
          return null;
        }),
        uuid: formatId,
      },
    });
    onClose();
  };

  return (
    <Portal otherClass={`${media.length > 0 ? ' max-w-[859px]' : ' max-w-[680px]'} min  w-full mx-auto !overflow-y-hidden`}>
      <div
        className={oneLine`flex flex-col rounded-3xl text-left h-full transform transition-all p-7 w-full overflow-y-hidden`}
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div className={oneLine`pb-3 flex items-center justify-between`}>
          <div className="dark:text-dark-400">Media Library</div>
          <span
            className={oneLine`text-lg  font-bold text-body cursor-pointer ease-in-out transition duration-300 hover:rotate-180`}
            aria-hidden="true"
            onClick={() => {
              onClose();
            }}
          >
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M8.98959 9.01041L21.0104 21.0312M21.0104 9.01041L8.98959 21.0312"
                stroke="#A7ACB3"
                strokeWidth="2.5"
                strokeLinecap="round"
              />
            </svg>
          </span>
        </div>
        <div
          className={oneLine`w-full flex flex-col flex-1 overflow-y-auto  ${
            media.length > 0 ? 'lg:w-[809px]' : 'flex justify-center items-center '
          } overflow-hidden `}
        >
          {loading &&
            <div>
              <div className="">
                <PulseLoader color={'#eeeff0'} size={10} loading={true} />
              </div>
            </div>
          }
          {!loading && media.length > 0 && (
            <>
              <div className="">
                <div className="flex items-center justify-between flex-1 mb-4 space-x-7">
                  <SearchBar isOpenSearch={() => {}} onSearch={e => setSearchText(e)} />
                </div>

                <div className="flex flex-col items-center px-4 py-4 mb-4 space-x-2 text-base font-medium text-left rounded-2xl dark:bg-white/5 bg-dark-200/5 md:text-lg text-lightGrey md:text-body dark:text-white">
                  <div className="w-full mb-2 font-normal">Your available visuals for the following features:</div>
                  <div className="flex flex-row justify-start w-full space-x-8">
                    <div className="flex items-center justify-start space-x-3 ">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect
                          x="10.834"
                          y="5.8335"
                          width="3.33333"
                          height="3.33333"
                          rx="1.66667"
                          stroke="#F64747"
                          strokeWidth="1.5"
                        />
                        <path
                          d="M3.93174 14.3345L5.38082 12.8854C6.73499 11.5312 8.93054 11.5312 10.2847 12.8854L11.7338 14.3345M11.7338 14.3345L12.3207 13.7475C13.4021 12.6661 15.1786 12.7467 16.1576 13.9216L16.5017 14.3345M11.7338 14.3345L13.901 16.5017M2.79406 12.5413C2.40198 10.8698 2.40198 9.13021 2.79406 7.45869C3.33698 5.14417 5.14417 3.33698 7.45869 2.79406C9.13021 2.40198 10.8698 2.40198 12.5413 2.79406C14.8558 3.33698 16.663 5.14417 17.2059 7.4587C17.598 9.13021 17.598 10.8698 17.2059 12.5413C16.663 14.8558 14.8558 16.663 12.5413 17.2059C10.8698 17.598 9.13021 17.598 7.4587 17.2059C5.14418 16.663 3.33698 14.8558 2.79406 12.5413Z"
                          stroke="#F64747"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>

                      {contentType === ILocationContentType.Video ? (
                        <span className="font-normal dark:text-dark-400">
                          <span className="text-dark-300">type:</span> MP4, JPG, PNG
                        </span>
                      ) : (
                        <span className="font-normal dark:text-dark-400">
                          <span className="text-dark-300">type:</span> JPG, PNG
                        </span>
                      )}
                    </div>
                    {contentType === ILocationContentType.Video && (
                      <div className="flex items-center justify-start pl-2 space-x-3 border-l border-bordercolor dark:border-bordercolordark dark:text-dark-400">
                        <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M2.01648 6.26619C2.59212 4.05879 4.35091 2.37001 6.55947 1.90403L6.9815 1.81498C8.972 1.39501 11.0267 1.39501 13.0172 1.81498L13.4392 1.90403C15.6478 2.37001 17.4066 4.05879 17.9822 6.26619C18.4495 8.05806 18.4495 9.94194 17.9822 11.7338C17.4066 13.9412 15.6478 15.63 13.4392 16.096L13.0172 16.185C11.0267 16.605 8.972 16.605 6.9815 16.185L6.55948 16.096C4.35091 15.63 2.59212 13.9412 2.01648 11.7338C1.54919 9.94194 1.54919 8.05806 2.01648 6.26619Z"
                            stroke="#F64747"
                            strokeWidth="1.5"
                          />
                          <path
                            d="M5 9H6.66667L8.33333 11.5L11.6667 6.5L13.3333 9H15"
                            stroke="#F64747"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>

                        <span>
                          resolution: {resolution[0]} {'x'} {resolution[1]}
                        </span>
                      </div>
                    )}
                  </div>
                </div>

                <div className="flex-1">
                  <div className="grid grid-cols-1 gap-6 md:grid-cols-3 ">
                    {filterMedia.map((item, index) => (
                      <div
                        key={index}
                        onClick={() => {
                          if (selectedItems.some(i => i === item.uid)) {
                            setSelectedItems([...selectedItems].filter(i => i !== item.uid));
                          } else {
                            setSelectedItems([...selectedItems, item.uid]);
                          }
                        }}
                        className="relative flex flex-col items-center justify-center p-2 cursor-pointer rounded-3xl dark:bg-white/5 bg-dark-200/5"
                      >
                        {}
                        <div className="absolute top-6 left-6">
                          <Checkbox
                            name="primary"
                            checked={selectedItems.some(i => i === item.uid)}
                            key={`${selectedItems.some(i => i === item.uid)}`}
                            labelColor="dark"
                          />
                        </div>

                        {item.file.type.match(/video/) ? (
                          <video className={oneLine` rounded-3xl h-48 object-cover w-full mx-auto block`}>
                            <source src={`${baseUrl}${item.filename}`} type="video/mp4" />
                          </video>
                        ) : (
                          <video
                            poster={`${baseUrl}${item.filename}`}
                            className={oneLine` rounded-3xl h-48 object-cover w-full mx-auto block`}
                          >
                            <source src={`${baseUrl}/${item.filename}`} type="image/" />
                          </video>
                        )}

                        <h3
                          title={item.oldName}
                          className={oneLine`block text-black dark:text-white font-normal w-full text-lg px-4 pt-2 text-left break-words min-h-[30px] text-container`}
                        >
                          {item.oldName}
                        </h3>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          )}
          {!loading && media.length === 0 && (
            <div className="flex flex-col items-center h-full">
              <div className="flex flex-col items-center justify-center flex-1 space-y-6">
                <img className="block dark:hidden" src="/images/no-media-dark.svg" alt="no-media" />
                <img className="hidden dark:block" src="/images/no-media.svg" alt="no-media" />
                <div className="flex flex-col items-center pt-1 space-x-2 text-base font-medium md:text-lg text-lightGrey md:text-body dark:text-white">
                  <div className="mb-2 font-normal">No uploaded media matches specifications for your selected screens</div>
                  <div className="flex flex-row justify-center w-full space-x-4">
                    <div className="flex items-center justify-start space-x-2 ">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect
                          x="10.834"
                          y="5.8335"
                          width="3.33333"
                          height="3.33333"
                          rx="1.66667"
                          stroke="#F64747"
                          strokeWidth="1.5"
                        />
                        <path
                          d="M3.93174 14.3345L5.38082 12.8854C6.73499 11.5312 8.93054 11.5312 10.2847 12.8854L11.7338 14.3345M11.7338 14.3345L12.3207 13.7475C13.4021 12.6661 15.1786 12.7467 16.1576 13.9216L16.5017 14.3345M11.7338 14.3345L13.901 16.5017M2.79406 12.5413C2.40198 10.8698 2.40198 9.13021 2.79406 7.45869C3.33698 5.14417 5.14417 3.33698 7.45869 2.79406C9.13021 2.40198 10.8698 2.40198 12.5413 2.79406C14.8558 3.33698 16.663 5.14417 17.2059 7.4587C17.598 9.13021 17.598 10.8698 17.2059 12.5413C16.663 14.8558 14.8558 16.663 12.5413 17.2059C10.8698 17.598 9.13021 17.598 7.4587 17.2059C5.14418 16.663 3.33698 14.8558 2.79406 12.5413Z"
                          stroke="#F64747"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>

                      {contentType === ILocationContentType.Video ? (
                        <span className="text-sm font-normal dark:text-dark-400">
                          {' '}
                          <span className="dark:text-dark-300">type:</span> MP4, JPG, PNG
                        </span>
                      ) : (
                        <span className="text-sm font-normal dark:text-dark-400">
                          {' '}
                          <span className="dark:text-dark-300">type:</span> JPG, PNG
                        </span>
                      )}
                    </div>
                    {contentType === ILocationContentType.Video && (
                      <div className="flex items-center justify-start pl-2 space-x-2 border-l border-bordercolor dark:border-bordercolordark dark:text-dark-400">
                        <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M2.01648 6.26619C2.59212 4.05879 4.35091 2.37001 6.55947 1.90403L6.9815 1.81498C8.972 1.39501 11.0267 1.39501 13.0172 1.81498L13.4392 1.90403C15.6478 2.37001 17.4066 4.05879 17.9822 6.26619C18.4495 8.05806 18.4495 9.94194 17.9822 11.7338C17.4066 13.9412 15.6478 15.63 13.4392 16.096L13.0172 16.185C11.0267 16.605 8.972 16.605 6.9815 16.185L6.55948 16.096C4.35091 15.63 2.59212 13.9412 2.01648 11.7338C1.54919 9.94194 1.54919 8.05806 2.01648 6.26619Z"
                            stroke="#F64747"
                            strokeWidth="1.5"
                          />
                          <path
                            d="M5 9H6.66667L8.33333 11.5L11.6667 6.5L13.3333 9H15"
                            stroke="#F64747"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>

                        <span className="text-sm font-normal dark:text-dark-400">
                          <span className="dark:text-dark-300">resolution:</span> {resolution[0]} {'x'} {resolution[1]}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* <div className="max-w-[300px] w-full">
                <Button type="button" color="secondary" onClick={onClose} fullWidth={true}>
                  {intl.formatMessage({
                    defaultMessage: 'Close',
                    id: 'Button.Close',
                  })}
                </Button>
              </div> */}
            </div>
          )}
        </div>
        <div className="flex items-center justify-center w-full pt-4 min-tablet:space-x-4 space-x-0 min-tablet:space-y-0 space-y-4 min-tablet:flex-row flex-col">
                <div className="max-w-[300px] w-full">
                  <Button type="button" color="secondary" onClick={onClose} fullWidth={true}>
                    {intl.formatMessage({
                      defaultMessage: 'Close',
                      id: 'Button.Close',
                    })}
                  </Button>
                </div>
                <div className="max-w-[300px] w-full">
                  <Button
                    type="button"
                    color="primary"
                    disabled={selectedItems.length === 0}
                    onClick={addVisual}
                    fullWidth={true}
                  >
                    {intl.formatMessage({
                      defaultMessage: 'Add Visual',
                      id: 'Button.AddVisual',
                    })}
                  </Button>
                </div>
        </div>
      </div>
     
    </Portal>
  );
};
