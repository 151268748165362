import React, { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useIntl, FormattedMessage } from 'react-intl';
import { useApp } from 'components/app';
import { Button, Input, RadioBox, SelectInput } from 'components/common/atoms';
import { Controller, useForm } from 'react-hook-form';

import { IOptions } from 'interface/ISelect';
import { ICampaignIndustries } from 'interface';
import { PublishContext } from 'context/publish/publish.provider';
import { IPublishType } from 'context/publish/publish.reducer';
import { useRouter } from 'next/router';
import { ILocationFull } from 'interface/ILocation';
import Navbar from '../Navbar';
import { JWT, TPSAPI } from '@api/users';
import { getTime, differenceInDays } from 'date-fns';
import useOnboardingContext from 'context/OnboardingContext';
import { useMondayContext } from 'context/monday/monday.reducer';
import { v4 as uuidv4 } from 'uuid';

const optionsRadio = [
  { value: 'HyperLocal', label: 'HyperLocal', checked: true },
  { value: 'Bulk Buying', label: 'Bulk Buying', checked: false },
];

const campaignCategoryRadio = [
  { value: 'standard', label: 'Standard', checked: true },
  { value: 'internal', label: 'Internal', checked: false },
  { value: 'managed', label: 'Managed', checked: true },
  { value: 'mediaplan', label: 'Media Plan', checked: true },
  { value: 'test', label: 'Test', checked: false },
];


type IProps = {
  industries: ICampaignIndustries[];
  screen?: ILocationFull;
};

const PublishBasic: React.FC<IProps> = ({ industries, screen }: IProps) => {
  const intl = useIntl();
  const router = useRouter();
  const { notify } = useApp();
  const [startDate, setStartDate] = useState<string | null>(null);

  const { publishState, dispatch } = React.useContext(PublishContext);
  const { initialState, state, dispatch: mondayDispatch } = useMondayContext();
  const [name, setName] = useState<string>(publishState.name || '');
  const [industry, setIndustry] = useState<IOptions | undefined>();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [campaignCategory, setCampaignCategory] = useState(
    publishState.category
      ? campaignCategoryRadio.find((item) => item.label.toLowerCase() === publishState.category.toLowerCase()) || campaignCategoryRadio[0]
      : campaignCategoryRadio[0]
  );
  const parentInputRef = React.useRef<HTMLInputElement | null>(null);
  const {
    setState,
    state: { run, tourActive, stepIndex, steps },
  } = useOnboardingContext();

  useEffect(() => {
    if (tourActive) {
      if (stepIndex < 3) {
        setState({ run: true, stepIndex: 3, steps, tourActive });
      } else {
        setState({ run: true, stepIndex: 4, steps, tourActive });
      }
    }
  }, []);

  useEffect(() => {
    let user = JWT.getJwtUser();
    if (user.role === 'admin') setIsAdmin(true);
  }, []);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(
        3,
        intl.formatMessage(
          {
            defaultMessage: 'The field needs to be at least {min} characters long',
            id: 'Error.minimumLength',
          },
          { min: '3' }
        )
      )
      .required(
        intl.formatMessage({
          defaultMessage: '*Campaign name is mandatory!',
          id: 'Error.mandatoryFieldd',
        })
      ),

    industry: Yup.string().required(
      intl.formatMessage({
        defaultMessage: '*Industry field is mandatory!',
        id: 'Error.mandatoryFieldd',
      })
    ),
    campCategory: Yup.string(),
  });

  const useYupValidationResolver = (schema: any) =>
    useCallback(
      async (data: any) => {
        try {
          const values = await schema.validate(data, {
            abortEarly: false,
          });

          return {
            values,
            errors: {},
          };
        } catch (errors: any) {
          return {
            values: {},
            errors: errors.inner.reduce(
              (allErrors: any, currentError: any) => ({
                ...allErrors,
                [currentError.path]: {
                  type: currentError.type ?? 'validation',
                  message: currentError.message,
                },
              }),
              {}
            ),
          };
        }
      },
      [schema]
    );

  const resolver = useYupValidationResolver(validationSchema);

  const [options, setOptions] = useState<IOptions[] | null>(null);
  const getDefaultCampaignName = () => {
    const { name: campaignName } = publishState;
    const { campaign_prefix: prefixSetOnUser } = JWT.getJwtUser();

    if (prefixSetOnUser) {
      const prefixCampaign = campaignName.substring(0, prefixSetOnUser?.length);
      if (prefixCampaign === prefixSetOnUser) {
        return campaignName;
      } else {
        return prefixSetOnUser + ' ' + campaignName;
      }
    } else {
      if (campaignName.startsWith('[MANAGED]') && router.query.cId) {
        return campaignName.replace('[MANAGED] ', '');
      } else {
        return campaignName;
      }
    }
  };
  React.useEffect(() => {
    screen &&
      notify(
        'Fill in the data and click on Continue.',
        'success',
        'top-right',
        'The screen was added to you campaign. '
      );
  }, [screen]);
  React.useEffect(() => {
    if (industries) {
      const optionMap: IOptions[] = industries.map(el => {
        const e: IOptions = {
          value: `${el.id}`,
          label: el.label,
        };
        if (publishState.industry) {
          if (publishState.industry === `${el.label}`) {
            setIndustry(e);
            setValue('industry', publishState.industry);
          }
        }
        return e;
      });
      setOptions(optionMap);
      if (publishState.industry) {
      }
    }
    return () => {};
  }, [industries]);


  const { register, handleSubmit, formState, setValue, control } = useForm<{ name: string; industry: string, campCategory:string }>({
    reValidateMode: 'onBlur',
    mode: 'onTouched',
    resolver,
    defaultValues: {
      name: '',
      industry: publishState.industry ? publishState.industry : '',
      campCategory: publishState.category ? publishState.category : ''
    },
  });
  useEffect(() => {
    //for
    // const nameValue: string | undefined = getDefaultCampaignName();
    if (publishState.name) {
      setName(publishState.name);
      setValue('name', publishState.name);
    }

    if (publishState.industry) {
      setIndustry({ value: publishState.industry, label: publishState.industry });
      setValue('industry', publishState.industry);
    }

    if (publishState.category) {
      const selectedCategory =
        campaignCategoryRadio.find((item) => item.label.toLowerCase() === publishState.category.toLowerCase()) || campaignCategoryRadio[0];
      setCampaignCategory(selectedCategory);
      setValue('campCategory', selectedCategory.label);
    }
  }, [publishState]);

  const { dirtyFields, errors } = formState;
  const getValidation = (inputName: any) => {
    const val = {
      isDirty: (dirtyFields as any)[inputName],
      error: (errors as any)[inputName],
    };
    return val;
  };

  const onSubmit = async (data: any) => {
    if (data.campType === 'Bulk Buying') {
      const uuid = uuidv4();
      mondayDispatch({
        type: 'setBasic',
        payload: {
          uuid,
          name: data.name,
          step: 1,
          industry: data.industry.label || data.industry,
          type: 'BulkBuying',
          category: data.campCategory ? data.campCategory.label || data.campCategory : "Standard"
        },
      });
      await TPSAPI.submitCampaign({
        ...initialState,
        step: 1,
        mondayCampaigns: [],
        uuid: uuid,
        name: data.name,
        industry: data.industry.label || data.industry,
        type: 'BulkBuying',
      });

      router.push(`/bulk-buying/brief/${uuid}`).catch(err => console.log(err));
    } else {
      let date = JWT.getJwtUser().role === 'admin' ? 0 : 2;
      const customDate = startDate;
      if (customDate) {
        const currentDate = new Date();

        const tempDate = new Date(customDate);

        const differenceDays = differenceInDays(tempDate, currentDate);
        date = differenceDays;
      }
      dispatch({
        type: IPublishType.setBasic,
        payload: {
          name: data.name,
          date: date,
          customDate,
          industry: data.industry.label || data.industry,
          type: 'HyperLocal',
          category: data.campCategory ? data.campCategory.label || data.campCategory : "Standard"
        },
      });
      router.push(`/publish/filters/?campaignId=${publishState.uuid}`);
    }
  };

  const updateCampaignNameWithPrefix = (selectedCategory: string) => {
    const currentName = name || ''; 
    const prefixRegex = /^\[.*?\]\s?/; 
    let newName = currentName.replace(prefixRegex, ''); 
  
 
    if (selectedCategory !== 'Standard') {
      newName = `[${selectedCategory.toUpperCase()}] ${newName.trim()}`;
    }
  
    setName(newName); 
    setValue('name', newName); 

    if (parentInputRef.current) {
      const inputElement = parentInputRef.current.querySelector('input'); 
      if (inputElement) {
        (inputElement as HTMLInputElement).focus(); 
      }
    }
  };

  return (
    <div className="flex flex-col min-h-screen page-section">
      <Navbar title="Publish" subtitle={name} backBtn="/" hideAddBtn={true} />
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col flex-1 pb-20 justify-evenly">
        <div className="flex items-center h-50">
          <img src="/images/publish-start.svg" loading="lazy" className="block m-auto dark:hidden" />
          <img src="/images/publish-start-dark.svg" loading="lazy" className="hidden m-auto dark:block" />
        </div>
        <div className="flex-col items-center justify-center mx-auto md:flex md:max-w-md lg:h-100-80 ">
          <div id="campaignForm" className="w-full">
            <Input
              name="name"
              data-cy="basicName"
              innerRef={register}
              parentRef={parentInputRef}
              label="Campaign name"
              validation={getValidation('name')}
              onChange={(event: any) => {
                setName(event.target.value);
              }}
            />
            {isAdmin && (
              <Controller
                control={control}
                name="campCategory"
                defaultValue={campaignCategoryRadio[0]}
                render={({ onChange, onBlur, value, ref }, { invalid, isTouched, isDirty }) => (
                  <SelectInput
                    key={campaignCategory.label}
                    options={campaignCategoryRadio}
                    onChange={e => {
                      setCampaignCategory(e);
                      updateCampaignNameWithPrefix(e.label);
                      onChange(e.label);
                    }}
                    label="Campaign Category"
                    defaultValue={campaignCategory || undefined}
                    isMulti={false}
                    fullWidth={true}
                    isClearable={false}
                    selName="campCategory"
                    tooltip={
                      <div className="excl-mark !cursor-pointer" data-tip data-for={'categoryTooltip'}>
                      ?
                      </div>
                    }
                  />
                )}
              />
            )}
            {options && (
              <Controller
                control={control}
                name="industry"
                defaultValue={industry?.label}
                render={({ onChange, onBlur, value, ref }, { invalid, isTouched, isDirty }) => (
                  <SelectInput
                    options={options}
                    defaultValue={industry || undefined}
                    onChange={e => {
                      setIndustry(e);
                      onChange(e.label);
                    }}
                    key={industry?.value}
                    label="Industry"
                    validation={getValidation('industry')}
                    data-cy="basicIndustry"
                    dataCy="basicIndustry"
                    isMulti={false}
                    fullWidth={true}
                    isClearable={false}
                    selName="industry"
                  />
                )}
              />
            )}
            {isAdmin && Object.keys(router.query).length === 0 && (
              <Controller
                control={control}
                name="campType"
                defaultValue={optionsRadio[0]}
                render={({ onChange, onBlur, value, ref }, { invalid, isTouched, isDirty }) => (
                  <SelectInput
                    options={optionsRadio}
                    onChange={e => {
                      onChange(e.label);
                    }}
                    label="Campaign Type"
                    defaultValue={optionsRadio[0]}
                    isMulti={false}
                    fullWidth={true}
                    isClearable={false}
                    selName="campType"
                  />
                )}
              />
            )}
            {isAdmin && (
              <Input
                type="date"
                name="startdate"
                data-cy="startdate"
                innerRef={register}
                label="Start date"
                onChange={(event: any) => {
                  setStartDate(event.target.value);
                }}
              />
            )}
          </div>
          <div>
            <div className="pt-16 mx-auto w-72 pb-7">
              <Button id="continuePublishBasic" type="submit" color="primary" loading={false} fullWidth={true}>
                {intl.formatMessage({
                  defaultMessage: 'Next',
                  id: 'Button.Next',
                })}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default PublishBasic;
